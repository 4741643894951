import styled from "styled-components";
import SelectInput from "../../../inputs/SelectInput";
import React, {useEffect} from "react";
import { useIntl } from "react-intl";
import { TextField } from "@mui/material";
import translate from "../../../../providers/i18n/translate";

const Root = styled.form`
  display: flex;
  flex-direction: column;
  width: 350px;
  height: auto;
  padding: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
`;

const StyledTextfield = styled(TextField)`
  width: 100%;
  @media (min-width: 1024px) {
    width: ${(props) => (props.width ? props.width : "inherit")};
  }
`;

const Title = styled.h4`
  font-size: 1.5rem;
  width: 100%;
  color: ${(props) => props.theme.palette.details};
  border-bottom: 1px solid ${(props) => props.theme.palette.details};
  height: auto;
  text-transform: uppercase;
`;

const NewSocialIcon = ({ onClose, handleEdit, handleNew, editData, selectedSocialIcons }) => {

  const socialIcons = [
    {
      fieldname: "Whatsapp",
      icon: "whatsapp.svg",
    },
    {
      fieldname: "Instagram",
      icon: "instagram.svg",
    },
    {
      fieldname: "Twitter",
      icon: "twitter.svg",
    },
    {
      fieldname: "Email",
      icon: "email.svg",
    },
    {
      fieldname: "Linkedin",
      icon: "linkedin.svg",
    },
    {
      fieldname: "Location",
      icon: "location.svg",
    },
    {
      fieldname: "Phone",
      icon: "phone.svg",
    },
    {
      fieldname: "Youtube",
      icon: "youtube.svg",
    },
    {
      fieldname: "Calendar",
      icon: "calendar.svg",
    },
    {
      fieldname: "Facebook",
      icon: "facebook.svg",
    },
    {
      fieldname: "Tripadvisor",
      icon: "tripadvisorIcon.png",
    },
  ];

  const [selectedSocialIcon, setSelectedSocialIcon] = React.useState(null);

  const [link, setLink] = React.useState("");

  const intl = useIntl();

  const handleChangeAutocomplete = (_, value) => {
    setSelectedSocialIcon(value);
  };

  const handleChange = (e) => {
    e.preventDefault();
    const { value } = e.target;
    setLink(value);
  };

  const onNew = (e) => {
    e.preventDefault();
    handleNew({
      src: selectedSocialIcon.value,
      link: link,
    });
    onClose();
  };

  const onEdit = (e) => {
    e.preventDefault();
    handleEdit(editData.index, {
      src: selectedSocialIcon.value,
      link: link,
    });
    onClose();
  };

  useEffect(() => {
    if(editData){
      setLink(editData.link);
      setSelectedSocialIcon(editData.src);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Root>
      <Title>{editData ? translate('edit') : translate("new")}</Title>
      <SelectInput
          data={editData ? socialIcons.filter(am => ( !selectedSocialIcons?.find(sa => sa.src === am.icon) || editData.src === am.icon ))  : socialIcons.filter(am => !selectedSocialIcons?.find(sa => sa.icon === am.icon))}
          value={selectedSocialIcon}
          fieldlabel="fieldname"
          fieldvalue="icon"
          label={intl.formatMessage({ id: "social icon type" })}
          name="type"
          onChange={handleChangeAutocomplete}
          width="100%"
          margin="normal"
          size="small"
          required
      />
      <StyledTextfield
        id="link"
        onChange={handleChange}
        value={link}
        label={intl.formatMessage({ id: "link" })}
        variant="outlined"
        width="100%"
        margin="dense"
        size="small"
        required
      />
      { editData ?
          <button onClick={onEdit}>{translate('edit')}</button>
          :
          <button onClick={onNew}>{translate('create')}</button>
      }
    </Root>
  );
};

export default NewSocialIcon;
