import React from "react";
import styled from "styled-components";
import translate from "../../providers/i18n/translate";
import { ThemeContext } from "../../providers/theme";
import { useIntl } from "react-intl";
import { TextField } from "@mui/material";
import SelectInput from "../inputs/SelectInput";
import ResponsiveInputImage from "../inputs/ResponsiveInputImage";
import axios from "axios";
import { API_BACKEND } from "../../config";
import { useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import * as actionCreators from "../../store/action-creators";
import { alertConstants } from "../../store/reducers/messageBar/alertConstants";

/* Styled components */
const Root = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  background-color: #fff;
  border-radius: 8px;
  padding: 20px;
  max-height: 90vh;
  overflow-y: auto;
  @media (min-width: 1024px) {
    width: 600px;
  }
`;

const Title = styled.h2`
  width: 100%;
  border-bottom: 1px solid
    ${(props) => (props.borderColor ? props.borderColor : "#000")};
  text-transform: uppercase;
  color: ${(props) => (props.color ? props.color : "#000")};
  margin: 0 0 10px 0;
`;

const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  padding: 5px;
  background-color: ${(props) => (props.bg ? props.bg : "#7A7A7A")};
  color: ${(props) => (props.color ? props.color : "#FFF")};
  border: none;
  outline: none;
  cursor: pointer;
  margin: 10px 0;
  transition: all 300ms ease-in-out;
  &:hover {
    transform: scale(1.1);
  }
`;

const ImageContainer = styled.div`
  width: 35%;
  aspect-ratio: 1/1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const InputData = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  @media (min-width: 1024px) {
    width: ${(props) => (props.width ? props.width : "inherit")};
  }
`;

const StyledTextfield = styled(TextField)`
  width: 100%;
  @media (min-width: 1024px) {
    width: ${(props) => (props.width ? props.width : "inherit")};
  }
`;


const InputError = styled.span`
  width: fit-content;
  height: ${(props) => (props.show ? "auto" : 0)};
  overflow: hidden;
  color: ${(props) => props.theme.palette.error};
  padding: ${(props) => (props.show ? "8px 10px" : 0)};
  opacity: ${(props) => (props.show ? 1 : 0)};
  border: ${(props) => props.theme.palette.error};
  background: ${(props) => `${props.theme.palette.error}10`};
  border-radius: 8px;
  margin-bottom: 10px;
  font-size: 0.875rem;
  transition: all 300ms ease-in-out;
`;

const HeroBannerInstitucionalContentMedia = ({ id, editData, handleMediaData, handleClose }) => {
  const theme = React.useContext(ThemeContext);

  const intl = useIntl();

  const dispatch = useDispatch();

  const { alertMessage } = bindActionCreators(actionCreators, dispatch);

  const selectOpt = [{
      label: intl.formatMessage({ id: "text" }),
      value: "text"
  },
  {
      label: intl.formatMessage({ id: "image" }),
      value: "img"
  }
  ]

  const onChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setData({
      ...data,
      [name]: value,
    });
  };

  const onChangeImage = (files) => {
      if (files.length > 0) {
          const formData = new FormData();
          formData.append("image", files[0]);
          axios
              .post(`${API_BACKEND}/sections/images`, formData, {})
              .then((res) => {
                  setData({
                      ...data,
                      img: res.data.url,
                  });
              })
              .catch((error) => {
                  alertMessage(error.response?.data?.msg, alertConstants.ERROR);
              });
      }
  };

  const onResetImage = () => {
      setData({
          ...data,
          img: null,
      });
  };

  const [data, setData] = React.useState(
    editData
      ? {
        ...editData
      }
      : {
        title: "",
        subtitle: "",
        titleColor: "#000000",
        subtitleColor: "#000000",
        contentType: selectOpt[0],
      }
  );

  const [errors, setErrors] = React.useState({
    contentType: {
        value: false,
        text: intl.formatMessage({ id: "content type must not be empty" }),
        validate: (value) => value == null || value === "", // returns true if there is an error and false if there is not
    },
  });

  const validateForm = () => {
    let foundError = false;
    const newErrors = {};
    Object.keys(errors).forEach((err) => {
      if (!foundError) {
        foundError = errors[err].validate(data[err]);
      }
      const val = errors[err].validate(data[err]);
      newErrors[err] = {
        ...errors[err],
        value: val,
      };
      setErrors({
        ...errors,
        ...newErrors,
      });
    });
    return !foundError;
  };

  const submit = () => {
    if (validateForm()){
      handleMediaData(id, {
      ...data,
      });
      handleClose();
    }
  };

  const handleChangeAutocomplete = (name, value) => {
      setData({
          ...data,
          [name]: value ?? "",
      });
  };

  return (
    <Root>
      <Title>
        {editData ? translate("edit content") : translate("add content")}
      </Title>
      <InputData width="100%">
        <SelectInput
            data={selectOpt}
            value={data?.contentType?.value}
            fieldlabel="label"
            fieldvalue="value"
            label={intl.formatMessage({ id: "content type" })}
            name="contentType"
            onChange={handleChangeAutocomplete}
            width="100%"
            margin="normal"
            size="small"
            required
        />
        <InputError show={errors.contentType.value}>{errors.contentType.text}</InputError>
      </InputData>
      { ( data?.contentType.value === "text" ) ?
        <>
        <InputData width="100%">
          <StyledTextfield
          id="title"
          name="title"
          onChange={onChange}
          value={data?.title}
          label={intl.formatMessage({ id: "title" })}
          variant="outlined"
          width="100%"
          margin="normal"
          size="small"
          />
        </InputData>
        <StyledTextfield
          id="titleColor"
          name="titleColor"
          onChange={onChange}
          value={data?.titleColor}
          label={intl.formatMessage({ id: "custom title color" })}
          variant="outlined"
          width="100%"
          margin="normal"
          size="small"
          type="color"
          required
        />
        <InputData width="100%">
          <StyledTextfield
          id="subtitle"
          name="subtitle"
          onChange={onChange}
          value={data?.subtitle}
          label={intl.formatMessage({ id: "subtitle" })}
          variant="outlined"
          width="100%"
          margin="normal"
          size="small"
          />
        </InputData>
         <StyledTextfield
          id="subtitleColor"
          name="subtitleColor"
          onChange={onChange}
          value={data?.subtitleColor}
          label={intl.formatMessage({ id: "custom subtitle color" })}
          variant="outlined"
          width="100%"
          margin="normal"
          size="small"
          type="color"
          required
        />
        </>
        :
        null
      }
      { ( data?.contentType.value === "img" ) ?
        <ImageContainer>
          <ResponsiveInputImage
            file={data.img}
            onChange={onChangeImage}
            onReset={onResetImage}
          />
        </ImageContainer>
        :
        null
      }
      <Button
        onClick={submit}
        bg={theme.palette.accent}
        color={theme.palette.base}
      >
        {theme.icons.check}
      </Button>
    </Root>
  );
};

export default HeroBannerInstitucionalContentMedia;
