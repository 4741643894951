import React from 'react';
import styled from 'styled-components';
import translate from '../../providers/i18n/translate';
import { ThemeContext } from '../../providers/theme';
import { useIntl } from 'react-intl';
import { TextField } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import SelectInput from '../inputs/SelectInput';
import { API_BACKEND, API_USERMANAGER } from '../../config';
import axios from 'axios';
import { decodeToken } from "../../hooks/jwt";
import { useAuth } from '../../providers/authentication';

/* Styled components */
const Root = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 600px;
    height: auto;
    background-color: #FFF;
    border-radius: 8px;
    padding: 20px;
`

const Title = styled.h2`
    width: 100%;
    border-bottom: 1px solid ${props => props.borderColor ? props.borderColor : "#000"};
    text-transform: uppercase;
    color: ${props => props.color ? props.color : "#000"};
    margin: 0 0 10px 0;
`

const Button = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    padding: 5px;
    background-color: ${props => props.bg ? props.bg : "#7A7A7A"};
    color: ${props => props.color ? props.color : "#FFF"};
    border: none;
    outline: none;
    cursor: pointer;
    margin: 10px 0;
    transition: all 300ms ease-in-out;
    &:hover {
        transform: scale(1.1);
    }
`

const ContentEditor = styled.div`
  position: relative;
  width: 100%;
  height: 300px;
`

const NewLayout = React.forwardRef(({layoutData, handleClose}, _ref) => {

    const theme = React.useContext(ThemeContext);

    const intl = useIntl();

    const { token } = useAuth();

    const userData = decodeToken(token);

    const [editorLoaded, setEditorLoaded] = React.useState(false);


    const [ newLayout, setNewLayout ] = React.useState(layoutData ?
        {
            name: layoutData.name,
        }
        :
        {
            name: "",
        }
    )

    const handleChange = (e) => {
        const { name, value } = e.target;
        setNewLayout({
            ...newLayout,
            [name]: value
        });
    }

    const handleEditor = (e, dataeditor) => {
        setNewLayout({
            ...newLayout,
            value: dataeditor,
        });
    };

    const valueParser = (value) => {
        var el = document.createElement( 'html' );
        el.innerHTML = value;
        if ( (layoutData ? layoutData.name : newLayout?.name?.value ) === 'analytics' ){
            try {
                JSON.parse(el.getElementsByTagName('code')[0].innerHTML);
            } catch (e) {
                return false;
            }
            return (JSON.stringify( JSON.parse(el.getElementsByTagName('code')[0].innerHTML) ));
        }
        return(el.getElementsByTagName('code')[0].innerHTML);

    }

    const handleChangeAutocomplete = (name, value) => {
        setNewLayout({
            ...newLayout,
            [name]: value
        });
    }


    const handleAction = (e) => {
        e.preventDefault();

        if(layoutData){
            if (valueParser(newLayout.value)){
                const url = `${API_BACKEND}/layouturation/${layoutData.id}`;
                const formData = new FormData();
                formData.append("value", valueParser(newLayout.value));
                formData.append("user", userData.id);
                axios.put(url, formData, {})
                    .then(response => {
                        handleClose();
                        /* alert(intl.formatMessage({ id: 'successful editing' })); */
                    })
                    .catch(error => {
                        console.log(error);
                        alert(error.response.data.msg);
                    })
            }
            else{
                alert(intl.formatMessage({ id: 'check syntax' }));
            }
        } else {

            const url = `${API_BACKEND}/layouts`;
            const formData = new FormData();
            formData.append("name", newLayout.name);
            formData.append("user", userData.id);
            axios.post(url, formData, {})
                .then(response => {
                    handleClose();
                })
                .catch(err => {
                    console.log(err);
                    alert(err.response.data.msg);
                })
        }
    }

    React.useEffect(() => {
        setEditorLoaded(true);
    }, [newLayout]);

    return (
        <Root>
            <Title>{layoutData ? translate("edit layout") : translate("new layout")}</Title>
            <TextField id="name" name="name" onChange={handleChange} value={newLayout.name ?? ''} label={intl.formatMessage({ id: 'name' })} variant="outlined" fullWidth margin="dense" size="small" required />
            <Button bg={theme.palette.accent} color={theme.palette.base} onClick={handleAction}><CheckIcon /></Button>
        </Root>
    );
})

export default NewLayout;
