import AccessDeniedPage from '../../pages/AccessDeniedPage';
import { useJwt } from "../../hooks/jwt";
import { useAuth } from "../../providers/authentication";
  
const Private = ({ children, scope, nullReturn }) => {

    const SCOPE_SEPARATOR = " ";

    const { token } = useAuth();

    const { decodedToken } = useJwt(token);

    if(decodedToken && decodedToken.scopes.split(SCOPE_SEPARATOR).includes("root")){
        return children;
    }

    if ((decodedToken && !decodedToken.scopes.split(SCOPE_SEPARATOR).includes(scope))) {
        return nullReturn ? null : <AccessDeniedPage />;
    }

    return children;
};

export default Private;