import React from 'react';
import styled from 'styled-components';
import { NavLink } from "react-router-dom";

const Root = styled.li`
    position: relative;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: flex-start;
    padding: 0 12.5px;
    margin: 8px 0;
    cursor: pointer;
    user-select: none;
    transition: all 200ms ease-in-out;
    &:hover {
        transform: scale(1.1);
    }
    @media (min-width: 1024px) {
        margin: 10px 0;
    }
`

const StyledNavLink = styled(NavLink)`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    text-decoration: none;
    color: ${props => props.theme.palette.base};
    font-size: 1.25rem;
    text-transform: uppercase;
    span {
        transition: all 300ms ease-in-out;
        opacity: ${props => props.showname ? 1 : 0};
        margin: 0 0 0 10px;
    }
    svg {
        transition: all 300ms ease-in-out;
        opacity: ${props => props.showname ? 1 : 0};
    }
    &.active {
        color: ${props => props.theme.palette.accent};
    }
    @media (min-width: 1024px) {
        font-size: 1rem;
        svg {
        opacity: 1;
    }
    }
`

const ItemList = ({item, showname, closeNav}) => {
    return (
        <Root>
            <StyledNavLink to={item.link} onClick={closeNav} showname={showname ? 1 : 0}>
                {item.icon}
                <span>{item.name}</span>
            </StyledNavLink>
        </Root>
    );
}

export default ItemList;
