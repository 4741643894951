import React from "react";
import styled from "styled-components";
import translate from "../../../../providers/i18n/translate";
import { ThemeContext } from "../../../../providers/theme";
import { Modal } from "@mui/material";
import NewInfo from "./newInfo";
import Info from "./info";

const Root = styled.section`
  display: flex;
  flex-direction: column;
`;

const Title = styled.div`
  position: relative;
  font-size: 1.2rem;
  width: 100%;
  color: ${(props) => props.theme.palette.details};
  border-bottom: 1px solid ${(props) => props.theme.palette.details};
  height: auto;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const InfosContainer = styled.div`
  width: 100%;
  display: flex;
`

const AddImage = styled.button`
  position: relative;
  color: ${(props) => props.theme.palette.principal};
  cursor: pointer;
  margin-right: 1rem;
  border: none;
  background: none;
`;

export default function InfoComponent({ infos, setFooterInfo }) {

  const theme = React.useContext(ThemeContext);

  const [open, setOpen] = React.useState(false);

  const [editInfoData, setEditInfoData] = React.useState(null);

  const onOpen = (e) => {
    e.preventDefault();
    setOpen(true);
  };

  const onEdit = (e) => {
    e.preventDefault();
    const {index} = e.currentTarget.dataset;
    setEditInfoData({
      index,
      ...infos[index]
    });
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
    setEditInfoData(null);
  };

  const editInfo = (index, info) => {
    const newInfos = [...infos];
    newInfos.splice(index, 1, {...info});
    setFooterInfo([...newInfos]);
  }

  const deleteInfo = (e) => {
    e.preventDefault();
    const {index} = e.currentTarget.dataset;
    const newInfos = [...infos];
    newInfos.splice(index, 1);
    setFooterInfo([...newInfos])
  };

  const addInfo = (newInfo) => {
    const newInfos = [...infos];
    newInfos.push(newInfo);
    setFooterInfo([...newInfos]);
  };

  return (
    <Root>
      <Title>
        {translate("rows")}
        <AddImage onClick={onOpen}>
          {theme.icons.add}
        </AddImage>
      </Title>
        <InfosContainer>
          {
            infos?.map((infoRow, index) => <Info key={`info-${index}`} index={index} link={infoRow.link} text={infoRow.text} handleEdit={onEdit} handleDelete={deleteInfo}/>)
          }
        </InfosContainer>
      <Modal open={open} onClose={onClose}>
        <NewInfo selectedInfos={infos} editData={editInfoData} handleEdit={editInfo} onClose={onClose} handleNew={addInfo} />
      </Modal>
    </Root>
  );
};
