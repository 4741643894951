import React from "react";
import {useNavigate, useParams} from "react-router-dom";
import {useIntl} from "react-intl";
import {useDispatch} from "react-redux";
import {bindActionCreators} from "redux";
import * as actionCreators from "../../store/action-creators";
import {useAuth} from "../../providers/authentication";
import {decodeToken} from "../../hooks/jwt";
import {CourseClient} from "./CourseClient";
import {Course} from "./Course";
import {alertConstants} from "../../store/reducers/messageBar/alertConstants";
import {CourseView} from "./CourseView";
import translate from "../../providers/i18n/translate";
import {AssertionsFailed} from "../../creationAssertion/AssertionsFailed";
import axios from "axios";
import {API_BACKEND} from "../../config";

const NewCourse = () => {
    const emptyCourse = {
        title: "",
        subTitle: "",
        slug: "",
        resume: "",
        duration: "",
        startDate: null,
        price: "",
        responsible: "",
        responsibleEmails: [],
        status: undefined,
        outstanding: false,
        type: "",
        multimediaData: {
            type: "",
            alter: "",
            epigraph: "",
        },
        cardMultimediaData: {
            type: "Image",
            alter: "",
            epigraph: "",
        },
        data: {},
        tags: [],
    }

    const {id} = useParams();

    const {token} = useAuth();

    const userData = decodeToken(token);

    const dispatch = useDispatch();
    const intl = useIntl();
    let navigate = useNavigate();

    const {alertMessage} = bindActionCreators(actionCreators, dispatch);
    const [data, setData] = React.useState(emptyCourse);
    const [curriculum, setCurriculum] = React.useState([]);
    const galleryRef = React.useRef();

    const [error, setError] = React.useState({
        hasError: false,
        fieldsError: {}
    })
    const [editorLoaded, setEditorLoaded] = React.useState(false);


    const loadImages = () => {
        galleryRef.current.handleSubmit();
    };

    const handleChange = (e) => {
        const {name, value} = e.target;
        setData({
            ...data,
            [name]: value,
        });
    };

    const handleChangeCheck = (e) => {
        const {name, checked} = e.target;
        setData({
            ...data,
            [name]: checked,
        });
    };

    const handleChangeCardMultimediaData = (e) => {
        const {name, value} = e.target;
        setData({
            ...data,
            cardMultimediaData: {
                ...data.cardMultimediaData,
                [name]: value,
            }
        });
    };

    const handleChangeMultimediaData = (e) => {
        const {name, value} = e.target;
        setData({
            ...data,
            multimediaData: {
                ...data.multimediaData,
                [name]: value,
            }
        });
    };

    const handleChangeEmails = (/** @type {any} */ value) => {
        setData({
            ...data,
            responsibleEmails: value,
        });
    };

    const handleChangeDate = (newValue) => {
        setData({
            ...data,
            startDate: newValue,
        });
    };

    const handleEditorDescription = (e, dataeditor) => {
        setData({
            ...data,
            resume: dataeditor,
        });
    };

    const submit = async (medias) => {
        try {
            const courseClient = new CourseClient()

            data.data.banner = medias.map((med) => {
                return {
                    id: med.id,
                    img: med.url,
                    imgDesktop: med.url,
                    imgTablet: med.url,
                    imgMobile: med.url,
                    alt: med.alt,
                    order: med.order,
                    data: med.data,
                };
            });
            data.data.curriculum = curriculum;
            data.userData = userData
            const newCourse = Course.creteForForm(data)
            await courseClient.addCourse(newCourse)

            alertMessage(intl.formatMessage({id: "course created"}), alertConstants.INFO,);
            navigate(-1);
        }
        catch (error){
            if (error instanceof AssertionsFailed) {
                setError({
                    hasError: true,
                    fieldsError: error.failedAssertions
                })
            }
            alertMessage(error.response?.data?.msg, alertConstants.ERROR);
        }
    };

    const handleChangeAutocomplete = (/** @type {any} */ name, /** @type {{ map: (arg0: (item: any) => any) => any; value: any; }} */ value) => {
        let newValue = value
        if (Array.isArray(value)) {
            newValue = value.map((item) => typeof item === 'object' ? item.value ?? '' : item)
        }
        if (typeof value === 'object' && !Array.isArray(value)) {
            newValue = value.value
        }
        setData({
            ...data,
            [name]: newValue,
        });
    };

    const handleChangeMultimediaAutocomplete = (/** @type {string} */ name, /** @type {{ value: any; }} */ value) => {
        let newValue = value;
        if (name === "type") {
            newValue = value.value
        }
        setData({
            ...data,
            multimediaData: {
                ...data.multimediaData,
                url: name === 'type' ? "" : data.multimediaData?.url ?? '',
                id: name === 'type' ? undefined : data.multimediaData?.id ?? undefined,
                [name]: newValue,
            },
        });
    };

    const onChangeContentImage = (files) => {
        if (files.length > 0) {
            setData({
                ...data,
                multimedia: files[0],
            });
        }
    };

    const onResetContentImage = () => {
        setData({
            ...data,
            multimedia: null,
        });
    };

    const onChangeCardImage = (files) => {
        if (files.length > 0) {
            setData({
                ...data,
                cardMultimedia: files[0],
            });
        }
    };

    const onResetCardImage = () => {
        setData({
            ...data,
            cardMultimedia: null,
        });
    };

    React.useEffect(() => {
        setEditorLoaded(true);
    }, []);


    return (
        <CourseView
            title={translate("new course")}
            data={data}
            loadImages={loadImages}
            onChangeCardImage={onChangeCardImage}
            onResetCardImage={onResetCardImage}
            handleChangeCardMultimediaData={handleChangeCardMultimediaData}
            error={error}
            handleChange={handleChange}
            handleChangeAutocomplete={handleChangeAutocomplete}
            handleChangeDate={handleChangeDate}
            handleChangeEmails={handleChangeEmails}
            handleChangeCheck={handleChangeCheck}
            handleEditorDescription={handleEditorDescription}
            handleChangeMultimediaAutocomplete={handleChangeMultimediaAutocomplete}
            onChangeContentImage={onChangeContentImage}
            onResetContentImage={onResetContentImage}
            handleChangeMultimediaData={handleChangeMultimediaData}
            galleryRef={galleryRef}
            submit={submit}
            curriculum={curriculum}
            setCurriculum={setCurriculum}
            editorLoaded={editorLoaded}
        />
    )
};

export default NewCourse;
