import styled from "styled-components";
import MenusContainer from "../../layout/nav/menusContainer";
import MenuItemModal from "./menuItemModal";
import { Modal } from "@mui/material";
import { useState } from "react";
import translate from "../../../providers/i18n/translate";
import React from "react";
import { ThemeContext } from "../../../providers/theme";

const Root = styled.div`
  position: absolute;
  width: 80%;
  height: 80%;
  background: white;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 20px;
  box-sizing: border-box;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.5);
  overflow: hidden;
  padding: 24px;
`;

const SectionTitle = styled.h4`
  font-size: 1.5rem;
  width: 100%;
  text-transform: capitalize;
  color: ${(props) => props.theme.palette.details};
  margin-bottom: 1rem;
`;

const Button = styled.button`
  position: absolute;
  bottom: 2%;
  left: 50%;
  translate: -50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  padding: 5px;
  background-color: ${(props) => (props.bg ? props.bg : "#7A7A7A")};
  color: ${(props) => (props.color ? props.color : "#FFF")};
  border: none;
  outline: none;
  cursor: pointer;
  margin: 10px 0;
  transition: all 300ms ease-in-out;
  &:hover {
    transform: scale(1.1);
  }
`;

export default function SubmenuModal({
  editData,
  addItem,
  editItem,
  handleDelete,
  handleOrder,
  setEditMenu,
  openSubmenuModal,
  editSubmenuData,
  setEditSubmenus,
  handleClose,
  setEditSubmenuData,
  data,
}) {

  const theme = React.useContext(ThemeContext);

  const [openMenuItemModal, setOpenMenuItemModal] = useState(false);

  const onCloseMenuItemModal = () => {
    setEditSubmenuData(null);
    setOpenMenuItemModal(false);
  }

  const handleAddSubmenu = (menuItem) => {
    addItem(menuItem, editData.parentIndex)
  }

  const handleSetEditSubmenu = (index, menuData) => {
    setEditMenu(index, menuData, editData.parentIndex)
  }

  const handleDeleteSubmenu = (index) => {
    handleDelete(index, editData.parentIndex)
  } 

  const handleSubmenuOrder = (orderedSubmenus) => {
    const newData = [...data];
    newData[editData.parentIndex].submenus = orderedSubmenus;
    setEditSubmenus(editData.parentIndex, orderedSubmenus);
    handleOrder(newData);
  }; 

  return (
    <Root>
      <SectionTitle>{translate("submenus")}</SectionTitle>
      <MenusContainer
        data={editData.submenus}
        handleDelete={handleDeleteSubmenu}
        handleOrder={handleSubmenuOrder}
        openModal={setOpenMenuItemModal}
        setEditMenu={handleSetEditSubmenu}
        openSubmenuModal={openSubmenuModal}
        submenu
      />
      <Button
        onClick={handleClose}
        bg={theme.palette.accent}
        color={theme.palette.base}
      >
        {theme.icons.check}
      </Button>
      <Modal open={openMenuItemModal} onClose={onCloseMenuItemModal}>
        <MenuItemModal
          addItem={handleAddSubmenu}
          editItem={editItem}
          handleClose={onCloseMenuItemModal}
          editData={editSubmenuData}
        />
      </Modal>
    </Root>
  );
}
