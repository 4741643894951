import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import translate from "../../providers/i18n/translate";
import TextField from "@mui/material/TextField";
import { useIntl } from "react-intl";
import BasicEditor from "../textEditors/basicEditor";
import Amenities from "./amenities";
import { API_BACKEND } from "../../config";
import axios from "axios";
import { useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import * as actionCreators from "../../store/action-creators";
import { alertConstants } from "../../store/reducers/messageBar/alertConstants";
import { useAuth } from "../../providers/authentication";
import { decodeToken } from "../../hooks/jwt";
import CheckInput from "../inputs/CheckInput";
import SelectInput from "../inputs/SelectInput";
import InputImage from "../inputs/inputImage";
import ControlledInputGallery from "../controlledInputGallery";
import TwoColumnsLodging from "./twoColumns/twoColumnsLodging";
import FourColumnsInput from "./fourColumns/FourColumnsInput";
import Timeline from "./timeline/Timeline";
import TwoColumnsWideImageProject from "./twoColumnsWideImage/twoColumnsWideImageProject";
import MapProject from "./mapProject/mapProject";
import FormProject from "./formProject/formProject";
import CardsBandCardsBottomArrows from "./cardsBandCardsBottomArrows/cardsBandCardsBottomArrows";
import FloorsDetails from "./floorsDetails/FloorsDetails";
import ChatButton from "./chatButton/ChatButton";

const Root = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 20px;
`;

const Header = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  height: 50px;
  border-bottom: 1px solid black;
`;

const Title = styled.h2`
  text-transform: uppercase;
`;

const Subtitle = styled.h4`
  font-size: 1.5rem;
  width: 100%;
  color: ${(props) => props.theme.palette.details};
  border-bottom: 1px solid ${(props) => props.theme.palette.details};
  height: 50px;
`;

const SubtitleSeparator = styled(Subtitle)`
  grid-column: 1/3;
`;

const Actions = styled.div`
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  gap: 20px;
  align-items: center;
  justify-content: flex-end;
`;

const ActionButton = styled.button`
  background: transparent;
  border: none;
  text-transform: capitalize;
  border: 1px solid rgba(0, 0, 0, 0.5);
  padding: 8px 10px;
  cursor: pointer;
  border-radius: 8px;
  &:hover {
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
  }
`;

const Container = styled.div`
  display: grid;
  width: 100%;
  height: calc(100% - 50px);
  grid-template-columns: 0.7fr 0.3fr;
  grid-template-rows: 400px 400px 400px auto;
  gap: 20px;
  padding: 20px 0;
`;

const Box = styled.div`
  width: 100%;
  height: calc(100% - 50px);
`;

const MetaData = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`;

const AmenitiesContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 20px;
  width: 100%;
  height: 100%;
  grid-column: 1/3;
`;

const TwoColumnsContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 20px;
  width: 100%;
  height: 100%;
  grid-column: 1/3;
`;

const FourColumnsContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 20px;
  width: 100%;
  height: 100%;
  grid-column: 1/3;
`;

const TimelineContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 20px;
  width: 100%;
  height: 100%;
  grid-column: 1/3;
`;

const WideImageContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 20px;
  width: 100%;
  height: 100%;
  grid-column: 1/3;
`;

const MapProjectContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 20px;
  width: 100%;
  height: 100%;
  grid-column: 1/3;
  min-height: 100px;
`;

const FormProjectContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 20px;
  width: 100%;
  height: 100%;
  grid-column: 1/3;
`;

const BluePrintsProjectContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 20px;
  width: 100%;
  height: 100%;
  grid-column: 1/3;
`;

const FloorsDetailsContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 20px;
  width: 100%;
  height: 100%;
  grid-column: 1/3;
`;

const GaleryContainer = styled(Box)`
  grid-column: 1/3;
  grid-row: 3/4;
`;

const InputError = styled.span`
  width: fit-content;
  height: ${(props) => (props.show ? "auto" : 0)};
  overflow: hidden;
  color: ${(props) => props.theme.palette.error};
  padding: ${(props) => (props.show ? "8px 10px" : 0)};
  opacity: ${(props) => (props.show ? 1 : 0)};
  border: ${(props) => props.theme.palette.error};
  background: ${(props) => `${props.theme.palette.error}10`};
  border-radius: 8px;
  margin-bottom: 10px;
  font-size: 0.875rem;
  transition: all 300ms ease-in-out;
`;

const LodgingDescriptions = styled.div`
  position: relative;
  display: grid;
  width: 100%;
  height: auto;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
`;

const Description = styled.div`
  position: relative;
  width: 100%;
  height: auto;
  grid-column: 1/3;
`;


const StyledTextfield = styled(TextField)`
  width: 100%;
  @media (min-width: 1024px) {
    width: ${(props) => (props.width ? props.width : "inherit")};
  }
`;

const BannerSection = styled.div`
  width: 100%;
  grid-column: 1/3;
`;

const ContainerInputs = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 20px;
  @media (min-width: 1024px) {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
`;

const ContainerImgWithAlt = styled.div`
  position: relative;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  grid-column: 2/3;
  grid-row: 2/3;
`;

const ImageContainer = styled.div`
  width: 300px;
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const NewProject = () => {
  const { id } = useParams();

  const { token } = useAuth();

  const userData = decodeToken(token);

  const [galleryBannerImages, setGalleryBannerImages] = React.useState([]);
  const [galleryProjectImages, setGalleryProjectImages] = React.useState([]);
  const [blueprintsProjectImages, setBlueprintsProjectImages] = React.useState([]);

  const dispatch = useDispatch();
  const intl = useIntl();
  let navigate = useNavigate();

  const { alertMessage } = bindActionCreators(actionCreators, dispatch);
  const [editorLoaded, setEditorLoaded] = React.useState(false);

  const [data, setData] = React.useState(null);

  const [fieldsEdited, setFieldsEdited] = React.useState([]);

  const [amenities, setAmenities] = React.useState([]);

  const [twoColumns, setTwoColumns] = React.useState({
    title: "",
    text: "",
    img: null,
    alt: "",
    action: "",
    link: "",
    invert: false,
  });

  const [fourColumns, setFourColumns] = React.useState({
    title: "",
    columns: [
      {
        title: "",
        text: "",
      },
      {
        title: "",
        text: "",
      },
      {
        title: "",
        text: "",
      },
      {
        title: "",
        text: "",
      },
    ],
  });

  const [timelineProject, setTimelineProject] = React.useState({
    title: "",
    img: null,
    alt: "",
    action: "",
    link: "",
    invert: false,
    srcSelect: "",
    videoType: "",
    srcVideo: "",
    idVideo: "",
  });

  const [wideImageProject, setWideImageProject] = React.useState({
    title: "",
    text: "",
    img: null,
    alt: "",
    action: "",
    link: "",
    invert: false,
  });

  const [mapProject, setMapProject] = React.useState({
    src: "",
  });

  const [formProject, setFormProject] = React.useState({
    title: "",
    subtitle: "",
    textButton: "",
    img: null,
    alt: "",
    from: [],
    to: [],
    subject: "",
    emailHeader: "",
    emailSignature: "",
  });

  const [chatButton, setChatButton] = React.useState({
    mobSideMargin: "",
    deskSideMargin: "",
    mobBottomMargin: "",
    deskBottomMargin: "",
    mobHeight: "",
    mobWidth: "",
    deskHeight: "",
    deskWidth: "",
    url: "",
    logo: null,
    position: "right",
  });

  const [blueprints, setBlueprints] = React.useState({
    title: "",
    action: "",
    link: "",
    autoheight: false,
  });

  const [floorDetails, setFloorDetails] = React.useState({
    title: "",
    sections: [
      {
        thead: [],
        tbody: []
      }
    ]
  })

  const parseImages = (toParse) => {
    return toParse.map((elem) => {
      return {
        id: elem.id,
        img: elem.img ? elem.img : elem.url,
        imgDesktop: elem.imgDesktop ? elem.imgDesktop : elem.url,
        imgTablet: elem.imgTablet ? elem.imgTablet : elem.url,
        imgMobile: elem.imgMobile ? elem.imgMobile : elem.url,
        alt: elem.alter,
        order: elem.order,
        data: elem.data,
      };
    });
  };

  const [errors, setErrors] = React.useState({
    name: {
      value: false,
      text: intl.formatMessage({ id: "title must not be empty" }),
      validate: (value) => value == null || value === "", // returns true if there is an error and false if there is not
    },
  });

  const addFieldEdited = (name) => {
    if (!fieldsEdited.includes(name)) {
      const newFieldsEdited = [...fieldsEdited];
      newFieldsEdited.push(name);
      setFieldsEdited([...newFieldsEdited]);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData({
      ...data,
      [name]: value,
    });
    addFieldEdited(name);
  };

  const onChangeAltLogo = (e) => {
    const { value } = e.target;
    setData({
      ...data,
      logoData: {
        ...data?.logoData,
        alter: value,
        epigraph: value,
        order: 1,
      },
    });
    addFieldEdited("logoData");
  };

  const handleChangeOutstanding = (name, value) => {
    setData({
      ...data,
      [name]: value,
    });
    addFieldEdited(name);
  };

  const handleEditorDescription = (e, dataeditor) => {
    setData({
      ...data,
      description: dataeditor,
    });
    addFieldEdited("description");
  };

  const handleChangeAutocomplete = (name, value) => {
    setData({
      ...data,
      [name]: value,
    });
    addFieldEdited(name);
  };

  const back = () => {
    navigate(-1);
  };

  const validateForm = () => {
    let foundError = false;
    const newErrors = {};
    Object.keys(errors).forEach((err) => {
      if (!foundError) {
        foundError = errors[err].validate(data[err]);
      }
      const val = errors[err].validate(data[err]);
      newErrors[err] = {
        ...errors[err],
        value: val,
      };
      setErrors({
        ...errors,
        ...newErrors,
      });
    });
    return !foundError;
  };

  const parseAmenities = (amenitiesList) => {
    return amenitiesList.map((am) => {
      return {
        icon: am.image,
        text: am.name,
      };
    });
  };

  const submit = () => {
    if (validateForm()) {
      let dataSectionsLodging = {};
      const formData = new FormData();
      formData.append("name", data.name);
      formData.append("description", data.description);
      formData.append("address", data.address);
      formData.append("order", data.order);
      formData.append(
        "status",
        typeof data.status === "object" ? data.status?.value : data.status
      );
      formData.append(
        "type",
        typeof data.type === "object" ? data.type?.value : data.type
      );
      formData.append(
        "tags",
        data?.tag?.map((t) => (t.value ? t.value : t.id)).join(",")
      );
      formData.append("outstanding", data.outstanding ? 1 : 0);
      galleryProjectImages.map((galleryImage) => {
        return formData.append(
          "images",
          galleryImage.file ? galleryImage.file : galleryImage.id
        );
      });
      formData.append(
        "filesDatas",
        JSON.stringify(
          galleryProjectImages.map((gp) => {
            const res = {
              alter: gp.alt ?? gp.alter,
              epigraph: gp.alt ?? gp.alter,
              order: gp.order,
            }
            if (!gp.file) {
              res.id = gp.id
            }
            return res
          })
        )
      );
      formData.append(
        "logo",
        data.logo && data.logo.id ? data.logo.id : data.logo
      );
      formData.append("logoData", JSON.stringify([data.logoData]));

      if (
        fieldsEdited.includes("imagesbanner") ||
        galleryBannerImages.length > 0
      ) {
        dataSectionsLodging["heroBannerBigText"] =
          parseImages(galleryBannerImages);
      }
      dataSectionsLodging["cardsBandCardsBottomArrows"] = {
        ...dataSectionsLodging["cardsBandCardsBottomArrows"],
        ...blueprints
      };
      if (blueprintsProjectImages.length > 0) {
        dataSectionsLodging["cardsBandCardsBottomArrows"] = {
          ...dataSectionsLodging["cardsBandCardsBottomArrows"],
          cards: parseImages(blueprintsProjectImages)
        }
      }
      dataSectionsLodging["amenitiesV3"] = {
        amenities: parseAmenities(amenities),
      };
      dataSectionsLodging["twoColumns"] = twoColumns;
      dataSectionsLodging["fourColumnFullText"] = fourColumns;
      dataSectionsLodging["twoColumnsTimeline"] = timelineProject;
      dataSectionsLodging["twoColumnsWideImage"] = wideImageProject;
      dataSectionsLodging["mapSection"] = mapProject;
      dataSectionsLodging["tableAccordion"] = floorDetails;
      const modifiedFormProject = {
        ...formProject,
        from: formProject.from.join(";"),
        to: formProject.to.join(";"),
      };
      dataSectionsLodging["formSection"] = modifiedFormProject;
      dataSectionsLodging["chatButton"] = chatButton;
      formData.append("data", JSON.stringify(dataSectionsLodging));
      formData.append("user", userData?.id);
      if (id) {
        axios
          .put(`${API_BACKEND}/lodgings/${id}`, formData, {})
          .then((res) => {
            alertMessage(
              intl.formatMessage({ id: "project updated" }),
              alertConstants.INFO
            );
            navigate(-1);
          })
          .catch((error) => {
            alertMessage(error.response?.data?.msg, alertConstants.ERROR);
          });
      } else {
        axios
          .post(`${API_BACKEND}/lodgings`, formData, {})
          .then((res) => {
            alertMessage(
              intl.formatMessage({ id: "project created" }),
              alertConstants.INFO
            );
            navigate(-1);
          })
          .catch((error) => {
            alertMessage(error.response?.data?.msg, alertConstants.ERROR);
          });
      }
    }
  };

  const onChangeImage = (files) => {
    if (files.length > 0) {
      setData({
        ...data,
        logo: files[0],
      });
    }
    addFieldEdited("logo");
  };

  const onResetImage = () => {
    setData({
      ...data,
      logo: null,
    });
    addFieldEdited("logo");
  };

  const loadImages = async (imagesToLoad, setImageState) => {
    const galleryImages = [];
    for (let index = 0; index < imagesToLoad.length; index++) {
      const res = await fetch(imagesToLoad[index].image);
      const buf = await res.arrayBuffer();
      const file = new File([buf], imagesToLoad[index].alt, {
        type: "image/webp",
      });
      galleryImages.push({
        ...imagesToLoad[index],
        file: file,
      });
    }
    setImageState([...galleryImages]);
  };

  const loadImagesUrl = async (imagesToLoad, setImageState) => {
    const galleryImages = [];
    for (let index = 0; index < imagesToLoad.length; index++) {
      const res = await fetch(imagesToLoad[index].img);
      const buf = await res.arrayBuffer();
      const file = new File([buf], imagesToLoad[index].alter, {
        type: "image/webp",
      });
      galleryImages.push({
        ...imagesToLoad[index],
        file: file,
      });
    }
    setImageState([...galleryImages]);
  };

  React.useEffect(() => {
    if (!data) {
      if (id) {
        axios
          .get(`${API_BACKEND}/lodgings/${id}`)
          .then((res) => {
            const lodging = res.data;
            setData({
              name: lodging.name,
              description: lodging.description,
              type: lodging.type.id,
              status: lodging.status.id,
              tag: lodging.tags,
              logo: lodging.logo[0],
              logoData: {
                alter: lodging.logo[0]?.alter,
                epigraph: lodging.logo[0]?.epigraph,
                order: lodging.logo[0]?.order,
                id: lodging.logo[0]?.id,
              },
              data: lodging.data,
              outstanding: lodging.outstanding,
              address: lodging.address,
              order: lodging.order,
            });
            loadImages(lodging.images, setGalleryProjectImages);
            loadImagesUrl(
              lodging.data?.heroBannerBigText,
              setGalleryBannerImages
            );
            setAmenities(
              lodging.data.amenitiesV3 && lodging.data.amenitiesV3.amenities
                ? lodging.data.amenitiesV3.amenities.map((am) => {
                  return {
                    image: am.icon,
                    name: am.text,
                  };
                })
                : []
            );
            setTwoColumns(
              lodging.data.twoColumns
                ? lodging.data.twoColumns
                : {
                  title: "",
                  text: "",
                  img: null,
                  alt: "",
                  action: "",
                  link: "",
                  invert: false,
                }
            );
            setFourColumns(
              lodging.data.fourColumnFullText
                ? lodging.data.fourColumnFullText
                : {
                  title: "",
                  columns: [
                    {
                      title: "",
                      text: "",
                    },
                    {
                      title: "",
                      text: "",
                    },
                    {
                      title: "",
                      text: "",
                    },
                    {
                      title: "",
                      text: "",
                    },
                  ],
                }
            );
            setTimelineProject(
              lodging.data.twoColumnsTimeline
                ? lodging.data.twoColumnsTimeline
                : {
                  title: "",
                  img: null,
                  alt: "",
                  action: "",
                  link: "",
                  invert: false,
                  srcSelect: "",
                  videoType: "",
                  srcVideo: "",
                  idVideo: "",
                }
            );
            setWideImageProject(
              lodging.data.twoColumnsWideImage
                ? lodging.data.twoColumnsWideImage
                : {
                  title: "",
                  text: "",
                  img: null,
                  alt: "",
                  action: "",
                  link: "",
                  invert: false,
                }
            );
            setMapProject(
              lodging.data.mapSection
                ? lodging.data.mapSection
                : {
                  src: "",
                }
            );
            setFormProject(
              lodging.data.formSection
                ?
                {
                  ...lodging.data.formSection,
                  from: lodging.data.formSection.from ? lodging.data.formSection.from.split(";") : [],
                  to: lodging.data.formSection.to ? lodging.data.formSection.to.split(";") : [],
                }
                : {
                  title: "",
                  subtitle: "",
                  textButton: "",
                  img: null,
                  alt: "",
                  from: "",
                  to: "",
                  subject: "",
                  emailHeader: "",
                  emailSignature: "",
                }
            );
            setChatButton(
              lodging.data.chatButton
                ? lodging.data.chatButton
                : {
                  mobSideMargin: "",
                  deskSideMargin: "",
                  mobBottomMargin: "",
                  deskBottomMargin: "",
                  mobHeight: "",
                  mobWidth: "",
                  deskHeight: "",
                  deskWidth: "",
                  url: "",
                  logo: null,
                  position: "right",
                },
            );
            if (lodging.data.cardsBandCardsBottomArrows) {
              setBlueprints(lodging.data.cardsBandCardsBottomArrows);
              loadImagesUrl(
                lodging.data?.cardsBandCardsBottomArrows?.cards,
                setBlueprintsProjectImages
              );
            } else {
              setBlueprints({
                title: "",
                action: "",
                link: "",
                autoheight: false,
              });
            }
            setFloorDetails(
              lodging.data.tableAccordion
                ? lodging.data.tableAccordion
                : {
                  title: "",
                  sections: [
                    {
                      thead: [],
                      tbody: []
                    }
                  ]
                }
            );
          })
          .catch((error) => {
            alertMessage(error.response?.data?.msg, alertConstants.ERROR);
          });
      } else {
        setData({
          name: "",
          description: "",
          checkInDescription: "",
          cancellationDescription: "",
          tag: [],
          type: null,
          status: null,
        });
      }
    }
    if (data) {
      setEditorLoaded(true);
    }
  }, [data]);

  return (
    <Root>
      <Header>
        <Title>
          {id ? translate("edit project") : translate("new project")}
        </Title>
        <Actions>
          <ActionButton onClick={submit}>{translate("apply")}</ActionButton>
          <ActionButton onClick={back}>{translate("cancel")}</ActionButton>
        </Actions>
      </Header>
      {data && (
        <Container>
          <BannerSection>
            <ControlledInputGallery
              id="imagesbanner"
              name="imagesbanner"
              title="Galería del Banner"
              data={galleryBannerImages}
              setData={setGalleryBannerImages}
              withContent={true}
              notifyEdit={addFieldEdited}
              contentMediaType="heroBannerBigText"
            />
          </BannerSection>
          <MetaData>
            <Subtitle>{translate("General data")}</Subtitle>
            <ContainerInputs>
              <StyledTextfield
                id="name"
                name="name"
                onChange={handleChange}
                value={data.name}
                label={intl.formatMessage({ id: "name" })}
                variant="outlined"
                width="70%"
                margin="dense"
                size="small"
                required
              />
              <CheckInput
                checked={data.outstanding}
                onChange={handleChangeOutstanding}
                label={intl.formatMessage({ id: "outstanding" })}
                name="outstanding"
                width="30%"
              />
            </ContainerInputs>
            <ContainerInputs>
              <SelectInput
                dataurl={`${API_BACKEND}/lodgingstatus`}
                value={data.status}
                fieldlabel="value"
                fieldvalue="id"
                label={intl.formatMessage({ id: "status" })}
                name="status"
                onChange={handleChangeAutocomplete}
                width="30%"
                margin="normal"
                size="small"
                required
              />
              <SelectInput
                dataurl={`${API_BACKEND}/lodgingtype`}
                value={data.type}
                fieldlabel="value"
                fieldvalue="id"
                label={intl.formatMessage({ id: "type" })}
                name="type"
                onChange={handleChangeAutocomplete}
                width="30%"
                margin="normal"
                size="small"
                required
              />
              <SelectInput
                multiple
                dataurl={`${API_BACKEND}/tags/list`}
                value={data.tag}
                fieldlabel="name"
                fieldvalue="id"
                label={intl.formatMessage({ id: "tag" })}
                name="tag"
                onChange={handleChangeAutocomplete}
                width="30%"
                margin="normal"
                size="small"
                required
              />
            </ContainerInputs>
            <ContainerInputs>
              <StyledTextfield
                id="address"
                name="address"
                onChange={handleChange}
                value={data.address}
                label={intl.formatMessage({ id: "address" })}
                variant="outlined"
                width="80%"
                margin="dense"
                size="small"
                required
              />
              <StyledTextfield
                id="order"
                name="order"
                type="number"
                onChange={handleChange}
                value={data.order}
                label={intl.formatMessage({ id: "order" })}
                variant="outlined"
                width="20%"
                margin="dense"
                size="small"
                required
              />
            </ContainerInputs>
            <LodgingDescriptions>
              <Description>
                <BasicEditor
                  name="description"
                  value={data?.description}
                  onChange={handleEditorDescription}
                  editorLoaded={editorLoaded}
                  maxContentHeight="150px"
                  minContentHeight="150px"
                  placeholder={intl.formatMessage({
                    id: "insert description here...",
                  })}
                />
              </Description>
            </LodgingDescriptions>
          </MetaData>
          <ContainerImgWithAlt>
            <span>{translate("logo")}</span>
            <ImageContainer>
              <InputImage
                file={
                  data?.logo && data?.logo?.id ? data?.logo.image : data.logo
                }
                onChange={onChangeImage}
                onReset={onResetImage}
              />
            </ImageContainer>
            <InputError show={errors.logo?.value}>
              {errors.logo?.text}
            </InputError>
            <StyledTextfield
              id="alt"
              name="alt"
              onChange={onChangeAltLogo}
              value={data?.logoData?.alter}
              label={intl.formatMessage({ id: "alternative text" })}
              variant="outlined"
              width="70%"
              margin="normal"
              size="small"
            />
          </ContainerImgWithAlt>
          <GaleryContainer>
            <ControlledInputGallery
              title="Imágenes del proyecto"
              id="images"
              name="images"
              data={galleryProjectImages}
              setData={setGalleryProjectImages}
              withContent={false}
              notifyEdit={addFieldEdited}
            />
          </GaleryContainer>
          <AmenitiesContainer>
            <Amenities amenities={amenities} setAmenities={setAmenities} />
          </AmenitiesContainer>
          <SubtitleSeparator>
            {translate("two columns section")}
          </SubtitleSeparator>
          <TwoColumnsContainer>
            <TwoColumnsLodging data={twoColumns} setData={setTwoColumns} />
          </TwoColumnsContainer>
          <SubtitleSeparator>
            {translate("four columns section")}
          </SubtitleSeparator>
          <FourColumnsContainer>
            <FourColumnsInput data={fourColumns} setData={setFourColumns} />
          </FourColumnsContainer>
          <SubtitleSeparator>{translate("timeline section")}</SubtitleSeparator>
          <TimelineContainer>
            <Timeline data={timelineProject} setData={setTimelineProject} />
          </TimelineContainer>
          <SubtitleSeparator>
            {translate("two columns wide image section")}
          </SubtitleSeparator>
          <WideImageContainer>
            <TwoColumnsWideImageProject
              data={wideImageProject}
              setData={setWideImageProject}
            />
          </WideImageContainer>
          <SubtitleSeparator>
            {translate("floors details section")}
          </SubtitleSeparator>
          <FloorsDetailsContainer>
            <FloorsDetails data={floorDetails} setData={setFloorDetails} />
          </FloorsDetailsContainer>
          <SubtitleSeparator>
            {translate("blueprints section")}
          </SubtitleSeparator>
          <BluePrintsProjectContainer>
            <CardsBandCardsBottomArrows
              data={blueprints}
              setData={setBlueprints}
            />
            <GaleryContainer>
              <ControlledInputGallery
                title="Planos del proyecto"
                id="blueprintsproject"
                name="blueprintsproject"
                data={blueprintsProjectImages}
                setData={setBlueprintsProjectImages}
                withContent={true}
                contentMediaType="blueprintsBand"
                notifyEdit={addFieldEdited}
              />
            </GaleryContainer>
          </BluePrintsProjectContainer>
          <SubtitleSeparator>{translate("map section")}</SubtitleSeparator>
          <MapProjectContainer>
            <MapProject data={mapProject} setData={setMapProject} />
          </MapProjectContainer>
          <SubtitleSeparator>{translate("form section")}</SubtitleSeparator>
          <FormProjectContainer>
            <FormProject data={formProject} setData={setFormProject} />
          </FormProjectContainer>
          <SubtitleSeparator>
            {translate("chatButton")}
          </SubtitleSeparator>
          <FormProjectContainer>
            <ChatButton data={chatButton} setData={setChatButton} />
          </FormProjectContainer>
        </Container>
      )}
    </Root>
  );
};

export default NewProject;
