import React from "react";
import {useNavigate, useParams} from "react-router-dom";
import translate from "../../providers/i18n/translate";
import {useIntl} from "react-intl";
import {API_BACKEND} from "../../config";
import {useDispatch} from "react-redux";
import {bindActionCreators} from "redux";
import * as actionCreators from "../../store/action-creators";
import {useAuth} from "../../providers/authentication";
import {decodeToken} from "../../hooks/jwt";
import {CourseClient} from "./CourseClient";
import {Course} from "./Course";
import {alertConstants} from "../../store/reducers/messageBar/alertConstants";
import axios from "axios";
import {CourseView} from "./CourseView";

const EditCourse = () => {
    const {id} = useParams();

    const {token} = useAuth();

    const userData = decodeToken(token);

    const dispatch = useDispatch();
    const intl = useIntl();
    let navigate = useNavigate();

    const {alertMessage} = bindActionCreators(actionCreators, dispatch);
    const [data, setData] = React.useState(null);
    const [curriculum, setCurriculum] = React.useState([]);
    const galleryRef = React.useRef();
    const [error, setError] = React.useState({
        hasError: false,
        fieldsError: {}
    })
    const [editorLoaded, setEditorLoaded] = React.useState(false);


    const loadImages = () => {
        galleryRef.current.handleSubmit();
    };

    const handleChange = (e) => {
        const {name, value} = e.target;
        setData({
            ...data,
            [name]: value,
        });
    };

    const handleChangeCheck = (e) => {
        const {name, checked} = e.target;
        setData({
            ...data,
            [name]: checked,
        });
    };

    const handleChangeCardMultimediaData = (e) => {
        const {name, value} = e.target;
        setData({
            ...data,
            cardMultimediaData: {
                ...data.cardMultimediaData,
                [name]: value,
            }
        });
    };

    const handleChangeMultimediaData = (e) => {
        const {name, value} = e.target;
        setData({
            ...data,
            multimediaData: {
                ...data.multimediaData,
                [name]: value,
            }
        });
    };

    const handleChangeEmails = (/** @type {any} */ value) => {
        setData({
            ...data,
            responsibleEmails: value,
        });
    };

    const handleChangeDate = (newValue) => {
        setData({
            ...data,
            startDate: newValue,
        });
    };

    const handleEditorDescription = (e, dataeditor) => {
        setData({
            ...data,
            resume: dataeditor,
        });
    };

    const submit = async (medias) => {
        try {
            const courseClient = new CourseClient()

            data.data.banner = medias.map((med) => {
                return {
                    id: med.id,
                    img: med.url,
                    imgDesktop: med.url,
                    imgTablet: med.url,
                    imgMobile: med.url,
                    alt: med.alt,
                    order: med.order,
                    data: med.data,
                };
            });
            data.data.curriculum = curriculum;
            data.userData = userData
            const newCourse = Course.creteForForm(data)
            await courseClient.updateCourse(id, newCourse)

            alertMessage(intl.formatMessage({id: "course updated"}), alertConstants.INFO,);
            navigate(-1);
        }
        catch (error){
            console.log(error.failedAssertions)
            setError({
                hasError: true,
                fieldsError: error.failedAssertions
            })
            alertMessage(error.response?.data?.msg, alertConstants.ERROR);
        }
    };

    const handleChangeAutocomplete = (/** @type {any} */ name, /** @type {{ map: (arg0: (item: any) => any) => any; value: any; }} */ value) => {
        let newValue = value
        if (Array.isArray(value)) {
            newValue = value.map((item) => typeof item === 'object' ? item.value ?? '' : item)
        }
        if (typeof value === 'object' && !Array.isArray(value)) {
            newValue = value.value
        }
        setData({
            ...data,
            [name]: newValue,
        });
    };

    const handleChangeMultimediaAutocomplete = (/** @type {string} */ name, /** @type {{ value: any; }} */ value) => {
        let newValue = value;
        if (name === "type") {
            newValue = value.value
        }
        setData({
            ...data,
            multimediaData: {
                ...data.multimediaData,
                url: name === 'type' ? "" : data.multimediaData?.url ?? '',
                id: name === 'type' ? undefined : data.multimediaData?.id ?? undefined,
                [name]: newValue,
            },
        });
    };

    const onChangeContentImage = ( files) => {
        if (files.length > 0) {
            setData({
                ...data,
                multimedia: files[0],
            });
        }
    };

    const onResetContentImage = () => {
        setData({
            ...data,
            multimedia: null,
        });
    };

    const onChangeCardImage = ( files) => {
        if (files.length > 0) {
            setData({
                ...data,
                cardMultimedia: files[0],
            });
        }
    };

    const onResetCardImage = () => {
        setData({
            ...data,
            cardMultimedia: null,
        });
    };

    React.useEffect(() => {
        try {
            if(!data) {
                const setCourse = async () => {
                    const res = await axios.get(`${API_BACKEND}/course/${id}`)
                    const course = res.data;
                    setData({
                        title: course.title ?? "",
                        subTitle: course.subTitle ?? "",
                        slug: course.slug ?? "",
                        resume: course.resume ?? "",
                        duration: course.duration ?? "",
                        startDate: course.startDate ?? null,
                        price: course.price ?? "",
                        responsible: course.responsible ?? "",
                        responsibleEmails: course.responsibleEmails ? course.responsibleEmails.split(';') : [],
                        status: course.status ? course.status.id : "",
                        outstanding: course.outstanding ?? 0,
                        type: course.type ? course.type.id : "",
                        multimediaData: course.multimedia !== null && course.multimedia !== undefined && Object.keys(course.multimedia).length !== 0 ? course.multimedia : {
                            type: "",
                            alter: "",
                            epigraph: ""
                        },
                        cardMultimediaData: course.cardMultimedia !== null && course.cardMultimedia !== undefined && Object.keys(course.cardMultimedia).length !== 0 ? course.cardMultimedia : {
                            type: "Image",
                            alter: "",
                            epigraph: ""
                        },
                        tags: course.tags ? course.tags.map(tag => tag.id) : [],
                        data: {
                            curriculum: course.data.curriculum ?? [],
                            banner: course.data?.banner ?? [],
                        },
                    });

                    setCurriculum(course.data?.curriculum ?? [])
                }

                setCourse()
            }
        }
        catch (error){
            alertMessage(error.msg, alertConstants.ERROR);
        }

        if (data) {
            setEditorLoaded(true);
        }

    }, [data]);

    const selectMediaType = [
        {
            label: intl.formatMessage({id: "image from file"}),
            value: "Image",
        },
        {
            label: intl.formatMessage({id: "video from URL"}),
            value: "Video",
        },
    ];

    return ( data &&
        <CourseView
            title={translate("edit course")}
            data={data}
            loadImages={loadImages}
            onChangeCardImage={onChangeCardImage}
            onResetCardImage={onResetCardImage}
            handleChangeCardMultimediaData={handleChangeCardMultimediaData}
            error={error}
            handleChange={handleChange}
            handleChangeAutocomplete={handleChangeAutocomplete}
            handleChangeDate={handleChangeDate}
            handleChangeEmails={handleChangeEmails}
            handleChangeCheck={handleChangeCheck}
            handleEditorDescription={handleEditorDescription}
            selectMediaType={selectMediaType}
            handleChangeMultimediaAutocomplete={handleChangeMultimediaAutocomplete}
            onChangeContentImage={onChangeContentImage}
            onResetContentImage={onResetContentImage}
            handleChangeMultimediaData={handleChangeMultimediaData}
            galleryRef={galleryRef}
            submit={submit}
            curriculum={curriculum}
            setCurriculum={setCurriculum}
            editorLoaded={editorLoaded}
        />
    )
};

export default EditCourse;
