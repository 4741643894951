import React from 'react';
import { ThemeProvider } from 'styled-components';

const ThemeContext = React.createContext();

const Provider = ({children, theme}) => (
	<ThemeContext.Provider value={theme} >
		<ThemeProvider theme={theme}>
			{children}
		</ThemeProvider>
	</ThemeContext.Provider>

);

export {
    ThemeContext,
    Provider
}

