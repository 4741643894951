import React from "react";
import styled from "styled-components";
import translate from "../../providers/i18n/translate";
import { ThemeContext } from "../../providers/theme";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { useIntl } from "react-intl";
import { API_BACKEND } from "../../config";
import { useAuth } from "../../providers/authentication";
import InputImage from "../inputs/inputImage";
import { bindActionCreators } from "redux";
import { useDispatch } from "react-redux";
import * as actionCreators from "../../store/action-creators";
import { alertConstants } from "../../store/reducers/messageBar/alertConstants";
import { decodeToken } from "../../hooks/jwt";
import { useEffect } from "react";
import MenuItemModal from "../layout/nav/menuItemModal";
import { Checkbox, FormControlLabel, Modal } from "@mui/material";
import MenusContainer from "../layout/nav/menusContainer";
import { TextField } from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import SocialIconSelector from "../layout/footer/socialIcons/socialIconsSelector.js";
import InfoComponent from "../layout/footer/info/infoComponent.js";
import CheckInput from "../inputs/CheckInput";
import SubmenuModal from "../layout/nav/submenuModal";
import { ColorPicker, useColor } from "react-color-palette";
import { hexToColorPickerObject } from "../../utils/colors.utils";

/* Styled components */
const Root = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: 50px 1fr;
  height: 100%;
  width: 100%;
  padding: 0 20px;
  @media (min-width: 1024px) {
    padding: 0 50px;
  }
`;

const Header = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  height: 50px;
  border-bottom: 1px solid black;
`;

const Actions = styled.div`
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  gap: 20px;
  align-items: center;
  justify-content: flex-end;
`;

const ActionButton = styled.button`
  background: transparent;
  text-transform: capitalize;
  border: 1px solid rgba(0, 0, 0, 0.5);
  padding: 8px 10px;
  cursor: pointer;
  border-radius: 8px;
  &:hover {
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
  }
`;

const InputsContainer = styled.div`
  position: relative;
  display: flex;
  flex-grow: 0;
  width: ${(props) => props.width ?? "100%"};
  gap: ${(props) => props.gap ?? ""};
  height: auto;
`;

const StyledTextfield = styled(TextField)`
  width: 100%;
  @media (min-width: 1024px) {
    width: ${(props) => (props.width ? props.width : "inherit")};
  }
`;

const Title = styled.h2`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  color: ${(props) => (props.color ? props.color : "#000")};
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
`;

const Subtitle = styled.summary`
  font-size: 1.5rem;
  text-transform: uppercase;
  font-weight: bold;
  color: ${(props) => props.theme.palette.details};
  margin-bottom: 1rem;
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
`;

const SectionWrapper = styled.div`
  border-radius: 20px;
  box-sizing: border-box;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  overflow: hidden;
  margin: 15px 0;
  padding: 24px;
`;

const SectionTitle = styled.h4`
  font-size: 1.5rem;
  width: 100%;
  text-transform: capitalize;
  color: ${(props) => props.theme.palette.details};
  margin-bottom: 1rem;
`;

const Content = styled.div`
  position: relative;
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  gap: 50px;
`;

const ContainerImgWithAlt = styled.div`
  position: relative;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
`;

const ImageContainer = styled.div`
  width: 300px;
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const Details = styled.details`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`;
// TODO: Improve modularization of LayoutConfig and validations to use InputError component
export default function LayoutConfig() {
  const { id } = useParams();

  const theme = React.useContext(ThemeContext);

  const { token } = useAuth();

  const userData = decodeToken(token);

  const dispatch = useDispatch();

  const { alertMessage } = bindActionCreators(actionCreators, dispatch);

  const navigate = useNavigate();

  const intl = useIntl();

  const [editData, setEditData] = React.useState(null);

  const [editSubmebus, setEditSubmebus] = React.useState(null);

  const [openMenuItemModal, setOpenMenuItemModal] = React.useState(false);

  const [openSubmenuModal, setOpenSubmenuModal] = React.useState(false);

  const [openActionItemModal, setOpenActionItemModal] = React.useState(false);

  const [parentMenuIndex, setParentMenuIndex] = React.useState(null);

  const [navBackgroundColor, setNavBackgroundColor] = useColor();

  const onCloseMenuItemModal = () => {
    setOpenMenuItemModal(false);
    setEditData(null);
  };

  const onCloseSubmenuModal = () => {
    setOpenSubmenuModal(false);
    setEditData(null);
  };

  const onCloseActionItemModal = () => {
    setOpenActionItemModal(false);
    setEditData(null);
  };
  const [layoutName, setLayoutName] = React.useState("");
  const [footerData, setFooterData] = React.useState(null);
  const [navData, setNavData] = React.useState(null);

  const handleAddMenu = (menuItem, parentMenuIndex) => {
    setNavData((prevState) => {
      let newMenus = [];
      if (parentMenuIndex === undefined || parentMenuIndex === null) {
        newMenus = [...prevState.items.menus.menuItems, menuItem];
      } else {
        newMenus = [...prevState.items.menus.menuItems];
        newMenus[parentMenuIndex]?.submenus?.push(menuItem);
      }
      return {
        ...prevState,
        items: {
          ...prevState.items,
          menus: {
            ...prevState.items.menus,
            menuItems: newMenus,
          },
        },
      };
    });
  };

  const handleAddAction = (actionItem) => {
    setNavData((prevState) => {
      let newActions = [];
      newActions = [...prevState.items.actions.actionItems, actionItem];
      return {
        ...prevState,
        items: {
          ...prevState.items,
          actions: {
            ...prevState.items.actions,
            actionItems: newActions,
          },
        },
      };
    });
  };

  const handleSetEditMenu = (index, menuData, parentMenuIndex) => {
    setEditData({
      index,
      menuData,
      parentMenuIndex,
    });
  };

  const handleSetEditAction = (index, actionData) => {
    setEditData({
      index,
      menuData: actionData,
    });
  };

  const handleSetEditSubmenus = (parentIndex, submenus) => {
    setEditSubmebus({
      parentIndex,
      submenus,
    });
  };

  const handleEditMenu = (menuItem, parentMenuIndex, index) => {
    setNavData((prevState) => {
      let newMenus = [];
      if (parentMenuIndex === undefined || parentMenuIndex === null) {
        newMenus = [...prevState.items.menus.menuItems];
        newMenus[index] = {
          ...newMenus[index],
          ...menuItem,
        };
      } else {
        newMenus = [...prevState.items.menus.menuItems];
        newMenus[parentMenuIndex].submenus[index] = menuItem;
      }
      return {
        ...prevState,
        items: {
          ...prevState.items,
          menus: {
            ...prevState.items.menus,
            menuItems: newMenus,
          },
        },
      };
    });
  };

  const handleEditAction = (actionItem, _parentMenuIndex, index) => {
    setNavData((prevState) => {
      const newActions = [...prevState.items.actions.actionItems];
      newActions[index] = {
        ...newActions[index],
        ...actionItem,
      };
      return {
        ...prevState,
        items: {
          ...prevState.items,
          actions: {
            ...prevState.items.actions,
            actionItems: newActions,
          },
        },
      };
    });
  };

  const handleDeleteMenu = (index, parentMenuIndex) => {
    setNavData((prevState) => {
      let newMenus = [];
      if (parentMenuIndex === null || parentMenuIndex === undefined) {
        newMenus = [...prevState.items.menus.menuItems];
        newMenus.splice(index, 1);
      } else {
        newMenus = [...prevState.items.menus.menuItems];
        newMenus[parentMenuIndex]?.submenus?.splice(index, 1);
      }
      return {
        ...prevState,
        items: {
          ...prevState.items,
          menus: {
            ...prevState.items.menus,
            menuItems: newMenus,
          },
        },
      };
    });
  };

  const handleDeleteAction = (index) => {
    setNavData((prevState) => {
      let newActions = [];
      newActions = [...prevState.items.actions.actionItems];
      newActions.splice(index, 1);
      return {
        ...prevState,
        items: {
          ...prevState.items,
          actions: {
            ...prevState.items.actions,
            actionItems: newActions,
          },
        },
      };
    });
  };

  const onChangeNavLogo = (files) => {
    if (files.length > 0) {
      const formData = new FormData();
      formData.append("image", files[0]);
      axios
        .post(`${API_BACKEND}/sections/images`, formData, {})
        .then((res) => {
          setNavData((prevState) => ({
            ...prevState,
            items: {
              ...prevState.items,
              logo: {
                ...prevState.items.logo,
                src: res.data.url,
              },
            },
          }));
        })
        .catch((error) => {
          alertMessage(error.response?.data?.msg, alertConstants.ERROR);
        });
    }
  };

  const onChangeFooterLogo = (files) => {
    if (files.length > 0) {
      const formData = new FormData();
      formData.append("image", files[0]);
      axios
        .post(`${API_BACKEND}/sections/images`, formData, {})
        .then((res) => {
          setFooterData((prevState) => ({
            ...prevState,
            logo: {
              ...prevState.logo,
              src: res.data.url,
            },
          }));
        })
        .catch((error) => {
          alertMessage(error.response?.data?.msg, alertConstants.ERROR);
        });
    }
  };

  const onChangeFooterBadges = (files) => {
    if (files.length > 0) {
      const formData = new FormData();
      formData.append("image", files[0]);
      axios
        .post(`${API_BACKEND}/sections/images`, formData, {})
        .then((res) => {
          setFooterData((prevState) => ({
            ...prevState,
            badges: {
              ...prevState.badges,
              src: res.data.url,
            },
          }));
        })
        .catch((error) => {
          alertMessage(error.response?.data?.msg, alertConstants.ERROR);
        });
    }
  };

  const handleLayoutNameChange = (e) => {
    const { value } = e.target;
    setLayoutName(value);
  };
  const onChangeNavLogoData = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setNavData((prevState) => ({
      ...prevState,
      items: {
        ...prevState.items,
        logo: {
          ...prevState.items.logo,
          [name]: value,
        },
      },
    }));
  };

  const onChangeFooterBadgesData = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setFooterData((prevState) => ({
      ...prevState,
      badges: {
        ...prevState.badges,
        [name]: value,
      },
    }));
  };

  const onChangeFooterLogoData = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setFooterData((prevState) => ({
      ...prevState,
      logo: {
        ...prevState.logo,
        [name]: value,
      },
    }));
  };

  const onChangeSocialIconsData = (e) => {
    const { name, value } = e.target;
    setFooterData((prevState) => ({
      ...prevState,
      socialIcons: {
        ...prevState.socialIcons,
        [name]: value,
      },
    }));
  };

  const onChangeSocialIconsArrangement = (name, value) => {
    setFooterData((prevState) => ({
      ...prevState,
      socialIcons: {
        ...prevState.socialIcons,
        [name]: value,
      },
    }));
  };

  const onChangeNavStyle = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setNavData((prevState) => ({
      ...prevState,
      style: {
        ...prevState.style,
        [name]: value,
      },
    }));
  };

  const onChangeFooterStyle = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setFooterData((prevState) => ({
      ...prevState,
      style: {
        ...prevState.style,
        [name]: value,
      },
    }));
  };

  const onChangeFooterInfoData = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setFooterData((prevState) => ({
      ...prevState,
      info: {
        ...prevState.info,
        [name]: value,
      },
    }));
  };

  const setSocialIcons = (socialIcons) => {
    setFooterData((prevState) => ({
      ...prevState,
      socialIcons: {
        ...prevState.socialIcons,
        icons: socialIcons,
      },
    }));
  };

  const setFooterInfo = (footerInfo) => {
    setFooterData((prevState) => ({
      ...prevState,
      info: {
        ...prevState.info,
        rows: footerInfo,
      },
    }));
  };

  const onResetNavLogo = () => {
    setNavData((prevState) => ({
      ...prevState,
      items: {
        ...prevState.items,
        logo: {
          ...prevState.items.logo,
          src: null,
        },
      },
    }));
  };

  const onResetFooterLogo = () => {
    setFooterData((prevState) => ({
      ...prevState,
      logo: {
        ...prevState.logo,
        src: null,
      },
    }));
  };

  const onResetFooterBadges = () => {
    setFooterData((prevState) => ({
      ...prevState,
      badges: {
        ...prevState.badges,
        src: null,
      },
    }));
  };

  const handleChangeCheckFooterLogo = (e) => {
    const { name, checked } = e.target;
    setFooterData((prevState) => ({
      ...prevState,
      logo: {
        ...prevState.logo,
        [name]: checked,
      },
    }));
  };

  const handleChangeCheckNavStyle = (e) => {
    const { name, checked } = e.target;
    setNavData((prevState) => ({
      ...prevState,
      style: {
        ...prevState.style,
        [name]: checked,
      },
    }));
  };

  const handleChangeCheckFooterBadges = (e) => {
    const { name, checked } = e.target;
    setFooterData((prevState) => ({
      ...prevState,
      badges: {
        ...prevState.badges,
        [name]: checked,
      },
    }));
  };

  const handleOrder = (orderedMenus) => {
    setNavData((prevState) => {
      return {
        ...prevState,
        items: {
          ...prevState.items,
          menus: {
            ...prevState.items.menus,
            menuItems: orderedMenus,
          },
        },
      };
    });
  };

  const back = () => {
    navigate(-1);
  };

  const submit = () => {
    const formattedNavData = structuredClone(navData);

    formattedNavData.style.background = navBackgroundColor.hex;

    const formData = new FormData();
    formData.append("name", layoutName);
    formData.append("user", userData?.id);
    formData.append("nav", JSON.stringify(formattedNavData));
    formData.append("footer", JSON.stringify(footerData));
    axios
      .put(`${API_BACKEND}/layouts/${id}`, formData, {})
      .then((res) => {
        alertMessage(
          intl.formatMessage({ id: "layout updated" }),
          alertConstants.INFO,
        );
        navigate(-1);
      })
      .catch((error) => {
        alertMessage(error.response?.data?.msg, alertConstants.ERROR);
      });
  };

  useEffect(() => {
    if (navData === null) {
      axios
        .get(`${API_BACKEND}/layouts/${id}`)
        .then((res) => {
          const name = res.data.name;
          setLayoutName(name ?? "");
          const nav = res.data.nav;
          setNavData((prevState) => ({
            ...prevState,
            items: {
              logo: {
                src: nav?.items?.logo?.src ?? null,
                alt: nav?.items?.logo?.alt ?? "",
                link: nav?.items?.logo?.link ?? "",
                order: nav?.items?.logo?.order ?? 0,
              },
              menus: {
                menuItems: nav?.items?.menus?.menuItems ?? [],
                width: nav?.items?.menus?.width ?? 0,
              },
              actions: {
                width: nav?.items?.actions?.width ?? 0,
                actionItems: nav?.items?.actions?.actionItems ?? [],
              },
            },
            style: {
              paddingTop: nav?.style?.paddingTop ?? 0,
              paddingRight: nav?.style?.paddingRight ?? 0,
              paddingBottom: nav?.style?.paddingBottom ?? 0,
              paddingLeft: nav?.style?.paddingLeft ?? 0,
              gap: nav?.style?.gap ?? 0,
              height: nav?.style?.height ?? 0,
              textColor: nav?.style?.textColor ?? "#FFFFFF",
              background: nav?.style?.background ?? "#000",
              align: nav?.style?.align ?? "",
              gradient: nav?.style?.gradient ?? false,
            },
          }));
          setNavBackgroundColor(
            hexToColorPickerObject(nav?.style?.background ?? "#000"),
          );
        })
        .catch((error) => {
          alertMessage(error.response?.data?.msg, alertConstants.ERROR);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navData]);

  useEffect(() => {
    if (footerData === null) {
      axios
        .get(`${API_BACKEND}/layouts/${id}`)
        .then((res) => {
          const footer = res.data.footer;
          setFooterData((prevState) => ({
            ...prevState,
            logo: {
              src: footer?.logo?.src ?? null,
              alt: footer?.logo?.alt ?? "",
              link: footer?.logo?.link ?? "",
              newTab: footer?.logo?.newTab ?? false,
              order: footer?.logo?.order ?? 0,
              width: footer?.logo?.width ?? 0,
              height: footer?.logo?.height ?? 0,
              marginLeft: footer?.logo?.marginLeft ?? 0,
              marginRight: footer?.logo?.marginRight ?? 0,
            },
            badges: {
              src: footer?.badges?.src ?? null,
              alt: footer?.badges?.alt ?? "",
              link: footer?.badges?.link ?? "",
              newTab: footer?.badges?.newTab ?? false,
              order: footer?.badges?.order ?? 0,
              width: footer?.badges?.width ?? 0,
              height: footer?.badges?.height ?? 0,
              marginLeft: footer?.badges?.marginLeft ?? 0,
              marginRight: footer?.badges?.marginRight ?? 0,
            },
            info: {
              rows: footer?.info?.rows ?? [],
              align: footer?.info?.align ?? "",
              order: footer?.info?.order ?? 0,
              marginLeft: footer?.info?.marginLeft ?? 0,
              marginRight: footer?.info?.marginRight ?? 0,
              gap: footer?.info?.gap ?? 0,
            },
            style: {
              paddingTop: footer?.style?.paddingTop ?? 0,
              paddingBottom: footer?.style?.paddingBottom ?? 0,
              gap: footer?.style?.gap ?? 0,
              minHeight: footer?.style?.minHeight ?? 0,
              maxHeight: footer?.style?.maxHeight ?? 0,
              background1: footer?.style?.background1 ?? "#FFFFFF",
              background2: footer?.style?.background2 ?? "#FFFFFF",
            },
            socialIcons: {
              icons: footer?.socialIcons?.icons ?? [],
              order: footer?.socialIcons?.order ?? 0,
              verticalArrangement:
                footer?.socialIcons?.verticalArrangement ?? false,
              marginLeft: footer?.socialIcons?.marginLeft ?? 0,
              marginRight: footer?.socialIcons?.marginRight ?? 0,
              gap: footer?.socialIcons?.gap ?? 0,
            },
          }));
        })
        .catch((error) => {
          alertMessage(error.response?.data?.msg, alertConstants.ERROR);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [footerData]);

  return (
    <Root>
      <Header>
        <Title>
          <Title color={theme.palette.principal}>{translate("layout")}</Title>
        </Title>
        <Actions>
          <ActionButton onClick={submit}>{translate("apply")}</ActionButton>
          <ActionButton onClick={back}>{translate("cancel")}</ActionButton>
        </Actions>
      </Header>
      <Content>
        <StyledTextfield
          id="layoutName"
          name="layoutName"
          onChange={handleLayoutNameChange}
          value={layoutName}
          label={intl.formatMessage({ id: "layout name" })}
          variant="outlined"
          width="70%"
          margin="normal"
          size="small"
        />
        {navData ? (
          <Details>
            <Subtitle>{translate("navbar")}</Subtitle>
            <p>{translate("layout_intro_navbar")}</p>
            <SectionWrapper>
              <SectionTitle>{translate("Logo")}</SectionTitle>
              <ContainerImgWithAlt>
                <ImageContainer>
                  <InputImage
                    id="nav-logo"
                    file={
                      navData?.items?.logo?.src?.id
                        ? navData?.items?.logo?.src?.image
                        : navData?.items?.logo?.src
                    }
                    onChange={onChangeNavLogo}
                    onReset={onResetNavLogo}
                  />
                </ImageContainer>
                <StyledTextfield
                  id="alt"
                  name="alt"
                  onChange={onChangeNavLogoData}
                  value={navData?.items?.logo?.alt}
                  label={intl.formatMessage({ id: "alternative text" })}
                  variant="outlined"
                  width="70%"
                  margin="normal"
                  size="small"
                />
                <StyledTextfield
                  id="link"
                  name="link"
                  onChange={onChangeNavLogoData}
                  value={navData?.items?.logo?.link}
                  label={intl.formatMessage({ id: "link" })}
                  variant="outlined"
                  width="70%"
                  margin="normal"
                  size="small"
                />
              </ContainerImgWithAlt>
            </SectionWrapper>
            <SectionWrapper>
              <SectionTitle>{translate("menus")}</SectionTitle>
              <MenusContainer
                data={navData?.items?.menus?.menuItems}
                handleDelete={handleDeleteMenu}
                handleOrder={handleOrder}
                openModal={setOpenMenuItemModal}
                setEditMenu={handleSetEditMenu}
                setEditSubmenus={handleSetEditSubmenus}
                openSubmenuModal={setOpenSubmenuModal}
              />
            </SectionWrapper>
            <SectionWrapper>
              <SectionTitle>{translate("actions")}</SectionTitle>
              <MenusContainer
                data={navData?.items?.actions?.actionItems}
                handleDelete={handleDeleteAction}
                handleOrder={handleOrder}
                openModal={setOpenActionItemModal}
                setEditMenu={handleSetEditAction}
              />
            </SectionWrapper>
            <SectionWrapper>
              <SectionTitle>{translate("style")}</SectionTitle>
              <InputsContainer width="45%" gap="20px">
                <StyledTextfield
                  id="paddingTop"
                  name="paddingTop"
                  onChange={onChangeNavStyle}
                  value={navData?.style?.paddingTop}
                  label={intl.formatMessage({ id: "paddingTop" })}
                  variant="outlined"
                  width="100%"
                  margin="normal"
                  size="small"
                  type="number"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">px</InputAdornment>
                    ),
                  }}
                  required
                />
                <StyledTextfield
                  id="paddingRight"
                  name="paddingRight"
                  onChange={onChangeNavStyle}
                  value={navData?.style?.paddingRight}
                  label={intl.formatMessage({ id: "paddingRight" })}
                  variant="outlined"
                  width="100%"
                  margin="normal"
                  size="small"
                  type="number"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">px</InputAdornment>
                    ),
                  }}
                  required
                />
                <StyledTextfield
                  id="paddingBottom"
                  name="paddingBottom"
                  onChange={onChangeNavStyle}
                  value={navData?.style?.paddingBottom}
                  label={intl.formatMessage({ id: "paddingBottom" })}
                  variant="outlined"
                  width="100%"
                  margin="normal"
                  size="small"
                  type="number"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">px</InputAdornment>
                    ),
                  }}
                  required
                />
                <StyledTextfield
                  id="paddingLeft"
                  name="paddingLeft"
                  onChange={onChangeNavStyle}
                  value={navData?.style?.paddingLeft}
                  label={intl.formatMessage({ id: "paddingLeft" })}
                  variant="outlined"
                  width="100%"
                  margin="normal"
                  size="small"
                  type="number"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">px</InputAdornment>
                    ),
                  }}
                  required
                />
              </InputsContainer>
              <InputsContainer width="45%">
                <StyledTextfield
                  id="gap"
                  name="gap"
                  onChange={onChangeNavStyle}
                  value={navData?.style?.gap}
                  label={intl.formatMessage({ id: "menus gap" })}
                  variant="outlined"
                  width="100%"
                  margin="normal"
                  size="small"
                  type="number"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">%</InputAdornment>
                    ),
                  }}
                  required
                />
                <StyledTextfield
                  id="align"
                  name="align"
                  onChange={onChangeNavStyle}
                  value={navData?.style?.align}
                  label={intl.formatMessage({ id: "align" })}
                  variant="outlined"
                  width="100%"
                  margin="normal"
                  size="small"
                />
              </InputsContainer>
              <InputsContainer width="45%">
                <StyledTextfield
                  id="height"
                  name="height"
                  onChange={onChangeNavStyle}
                  value={navData?.style?.height}
                  label={intl.formatMessage({ id: "height" })}
                  variant="outlined"
                  width="100%"
                  margin="normal"
                  size="small"
                  type="number"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">px</InputAdornment>
                    ),
                  }}
                  required
                />
              </InputsContainer>
              <InputsContainer width="45%">
                <StyledTextfield
                  id="nav_text_color"
                  name="textColor"
                  onChange={onChangeNavStyle}
                  value={navData?.style?.textColor}
                  label={intl.formatMessage({ id: "textColor" })}
                  variant="outlined"
                  width="100%"
                  margin="normal"
                  size="small"
                  type="color"
                  required
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      name="gradient"
                      checked={navData?.style?.gradient}
                      onChange={handleChangeCheckNavStyle}
                      inputProps={{ "aria-label": "controlled invert" }}
                    />
                  }
                  label={intl.formatMessage({ id: "gradient" })}
                />
              </InputsContainer>
              <InputsContainer width="45%">
                <ColorPicker
                  width={250}
                  height={200}
                  color={navBackgroundColor}
                  onChange={setNavBackgroundColor}
                  alpha
                  hideHSV
                  dark
                />
              </InputsContainer>
            </SectionWrapper>
          </Details>
        ) : null}
        {footerData ? (
          <Details>
            <Subtitle>{translate("footer")}</Subtitle>
            <p>{translate("layout_intro_footer")}</p>
            <SectionWrapper>
              <SectionTitle>{translate("Logo")}</SectionTitle>
              <ContainerImgWithAlt>
                <ImageContainer>
                  <InputImage
                    id="footer-logo"
                    file={
                      footerData?.logo?.src?.id
                        ? footerData?.logo?.src?.image
                        : footerData?.logo?.src
                    }
                    onChange={onChangeFooterLogo}
                    onReset={onResetFooterLogo}
                  />
                </ImageContainer>
                <StyledTextfield
                  id="alt"
                  name="alt"
                  onChange={onChangeFooterLogoData}
                  value={footerData?.logo?.alt}
                  label={intl.formatMessage({ id: "alternative text" })}
                  variant="outlined"
                  width="70%"
                  margin="normal"
                  size="small"
                />
                <StyledTextfield
                  id="link"
                  name="link"
                  onChange={onChangeFooterLogoData}
                  value={footerData?.logo?.link}
                  label={intl.formatMessage({ id: "link" })}
                  variant="outlined"
                  width="70%"
                  margin="normal"
                  size="small"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      name="newTab"
                      checked={footerData?.logo?.newTab}
                      onChange={handleChangeCheckFooterLogo}
                      inputProps={{ "aria-label": "controlled invert" }}
                    />
                  }
                  label={intl.formatMessage({ id: "new tab" })}
                />
                <StyledTextfield
                  id="order"
                  name="order"
                  onChange={onChangeFooterLogoData}
                  value={footerData?.logo?.order}
                  label={intl.formatMessage({ id: "order" })}
                  variant="outlined"
                  width="70%"
                  margin="normal"
                  size="small"
                />
                <StyledTextfield
                  id="width"
                  name="width"
                  onChange={onChangeFooterLogoData}
                  value={footerData?.logo?.width}
                  label={intl.formatMessage({ id: "width" })}
                  variant="outlined"
                  width="70%"
                  margin="normal"
                  size="small"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">px</InputAdornment>
                    ),
                  }}
                />
                <StyledTextfield
                  id="height"
                  name="height"
                  onChange={onChangeFooterLogoData}
                  value={footerData?.logo?.height}
                  label={intl.formatMessage({ id: "height" })}
                  variant="outlined"
                  width="70%"
                  margin="normal"
                  size="small"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">px</InputAdornment>
                    ),
                  }}
                />
                <InputsContainer width="70%" gap="20px">
                  <StyledTextfield
                    id="footer-logo-margin-left"
                    name="marginLeft"
                    onChange={onChangeFooterLogoData}
                    value={footerData?.logo?.marginLeft}
                    label={intl.formatMessage({ id: "marginLeft" })}
                    variant="outlined"
                    width="100%"
                    margin="normal"
                    type="number"
                    size="small"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">px</InputAdornment>
                      ),
                    }}
                    required
                  />
                  <StyledTextfield
                    id="footer-logo-margin-right"
                    name="marginRight"
                    onChange={onChangeFooterLogoData}
                    value={footerData?.logo?.marginRight}
                    label={intl.formatMessage({ id: "marginRight" })}
                    variant="outlined"
                    width="100%"
                    margin="normal"
                    type="number"
                    size="small"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">px</InputAdornment>
                      ),
                    }}
                    required
                  />
                </InputsContainer>
              </ContainerImgWithAlt>
            </SectionWrapper>
            <SectionWrapper>
              <SectionTitle>{translate("info")}</SectionTitle>
              <InfoComponent
                infos={footerData?.info?.rows}
                setFooterInfo={setFooterInfo}
              />
              <StyledTextfield
                id="order"
                name="order"
                onChange={onChangeFooterInfoData}
                value={footerData?.info?.order}
                label={intl.formatMessage({ id: "order" })}
                variant="outlined"
                width="70%"
                margin="normal"
                size="small"
              />
              <StyledTextfield
                id="align"
                name="align"
                onChange={onChangeFooterInfoData}
                value={footerData?.info?.align}
                label={intl.formatMessage({ id: "align" })}
                variant="outlined"
                width="70%"
                margin="normal"
                size="small"
              />
              <InputsContainer width="70%" gap="20px">
                <StyledTextfield
                  id="footer-info-margin-left"
                  name="marginLeft"
                  onChange={onChangeFooterInfoData}
                  value={footerData?.info?.marginLeft}
                  label={intl.formatMessage({ id: "marginLeft" })}
                  variant="outlined"
                  width="100%"
                  margin="normal"
                  type="number"
                  size="small"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">px</InputAdornment>
                    ),
                  }}
                  required
                />
                <StyledTextfield
                  id="footer-info-margin-right"
                  name="marginRight"
                  onChange={onChangeFooterInfoData}
                  value={footerData?.info?.marginRight}
                  label={intl.formatMessage({ id: "marginRight" })}
                  variant="outlined"
                  width="100%"
                  margin="normal"
                  type="number"
                  size="small"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">px</InputAdornment>
                    ),
                  }}
                  required
                />
                <StyledTextfield
                  id="footer-info-gap"
                  name="gap"
                  onChange={onChangeFooterInfoData}
                  value={footerData?.info?.gap}
                  label={intl.formatMessage({ id: "gap" })}
                  variant="outlined"
                  width="100%"
                  margin="normal"
                  type="number"
                  size="small"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">px</InputAdornment>
                    ),
                  }}
                  required
                />
              </InputsContainer>
            </SectionWrapper>
            <SectionWrapper>
              <SectionTitle>{translate("social icons")}</SectionTitle>
              <SocialIconSelector
                socialIcons={footerData?.socialIcons?.icons}
                setSocialIcons={setSocialIcons}
              />
              <StyledTextfield
                id="order"
                name="order"
                onChange={onChangeSocialIconsData}
                value={footerData?.socialIcons?.order}
                label={intl.formatMessage({ id: "order" })}
                variant="outlined"
                width="70%"
                type="number"
                margin="normal"
                size="small"
              />
              <CheckInput
                checked={footerData?.socialIcons?.verticalArrangement}
                onChange={onChangeSocialIconsArrangement}
                label={intl.formatMessage({ id: "verticalArrangement" })}
                name="verticalArrangement"
                width="30%"
              />
              <InputsContainer width="70%" gap="20px">
                <StyledTextfield
                  id="footer-social-icons-margin-left"
                  name="marginLeft"
                  onChange={onChangeSocialIconsData}
                  value={footerData?.socialIcons?.marginLeft}
                  label={intl.formatMessage({ id: "marginLeft" })}
                  variant="outlined"
                  width="100%"
                  margin="normal"
                  type="number"
                  size="small"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">px</InputAdornment>
                    ),
                  }}
                  required
                />
                <StyledTextfield
                  id="footer-social-icons-margin-right"
                  name="marginRight"
                  onChange={onChangeSocialIconsData}
                  value={footerData?.socialIcons?.marginRight}
                  label={intl.formatMessage({ id: "marginRight" })}
                  variant="outlined"
                  width="100%"
                  margin="normal"
                  type="number"
                  size="small"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">px</InputAdornment>
                    ),
                  }}
                  required
                />
                <StyledTextfield
                  id="footer-social-icons-gap"
                  name="gap"
                  onChange={onChangeSocialIconsData}
                  value={footerData?.socialIcons?.gap}
                  label={intl.formatMessage({ id: "gap" })}
                  variant="outlined"
                  width="100%"
                  margin="normal"
                  type="number"
                  size="small"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">px</InputAdornment>
                    ),
                  }}
                  required
                />
              </InputsContainer>
            </SectionWrapper>
            <SectionWrapper>
              <SectionTitle>{translate("badges")}</SectionTitle>
              <ContainerImgWithAlt>
                <ImageContainer>
                  <InputImage
                    id="footer-badges"
                    file={
                      footerData?.badges?.src?.id
                        ? footerData?.badges?.src?.image
                        : footerData?.badges?.src
                    }
                    onChange={onChangeFooterBadges}
                    onReset={onResetFooterBadges}
                  />
                </ImageContainer>
                <StyledTextfield
                  id="alt"
                  name="alt"
                  onChange={onChangeFooterBadgesData}
                  value={footerData?.badges?.alt}
                  label={intl.formatMessage({ id: "alternative text" })}
                  variant="outlined"
                  width="70%"
                  margin="normal"
                  size="small"
                />
                <StyledTextfield
                  id="link"
                  name="link"
                  onChange={onChangeFooterBadgesData}
                  value={footerData?.badges?.link}
                  label={intl.formatMessage({ id: "link" })}
                  variant="outlined"
                  width="70%"
                  margin="normal"
                  size="small"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      name="newTab"
                      checked={footerData?.badges?.newTab}
                      onChange={handleChangeCheckFooterBadges}
                      inputProps={{ "aria-label": "controlled invert" }}
                    />
                  }
                  label={intl.formatMessage({ id: "new tab" })}
                />
                <StyledTextfield
                  id="order"
                  name="order"
                  onChange={onChangeFooterBadgesData}
                  value={footerData?.badges?.order}
                  label={intl.formatMessage({ id: "order" })}
                  variant="outlined"
                  width="70%"
                  margin="normal"
                  size="small"
                />
                <StyledTextfield
                  id="width"
                  name="width"
                  onChange={onChangeFooterBadgesData}
                  value={footerData?.badges?.width}
                  label={intl.formatMessage({ id: "width" })}
                  variant="outlined"
                  width="70%"
                  margin="normal"
                  size="small"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">px</InputAdornment>
                    ),
                  }}
                />
                <StyledTextfield
                  id="height"
                  name="height"
                  onChange={onChangeFooterBadgesData}
                  value={footerData?.badges?.height}
                  label={intl.formatMessage({ id: "height" })}
                  variant="outlined"
                  width="70%"
                  margin="normal"
                  size="small"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">px</InputAdornment>
                    ),
                  }}
                />
                <InputsContainer width="70%" gap="20px">
                  <StyledTextfield
                    id="footer-badges-margin-left"
                    name="marginLeft"
                    onChange={onChangeFooterBadgesData}
                    value={footerData?.badges?.marginLeft}
                    label={intl.formatMessage({ id: "marginLeft" })}
                    variant="outlined"
                    width="100%"
                    margin="normal"
                    type="number"
                    size="small"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">px</InputAdornment>
                      ),
                    }}
                    required
                  />
                  <StyledTextfield
                    id="footer-badges-margin-right"
                    name="marginRight"
                    onChange={onChangeFooterBadgesData}
                    value={footerData?.badges?.marginRight}
                    label={intl.formatMessage({ id: "marginRight" })}
                    variant="outlined"
                    width="100%"
                    margin="normal"
                    type="number"
                    size="small"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">px</InputAdornment>
                      ),
                    }}
                    required
                  />
                </InputsContainer>
              </ContainerImgWithAlt>
            </SectionWrapper>
            <SectionWrapper>
              <SectionTitle>{translate("style")}</SectionTitle>
              <InputsContainer width="45%" gap="20px">
                <StyledTextfield
                  id="paddingTop"
                  name="paddingTop"
                  onChange={onChangeFooterStyle}
                  value={footerData?.style?.paddingTop}
                  label={intl.formatMessage({ id: "paddingTop" })}
                  variant="outlined"
                  width="100%"
                  margin="normal"
                  type="number"
                  size="small"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">px</InputAdornment>
                    ),
                  }}
                  required
                />
                <StyledTextfield
                  id="paddingBottom"
                  name="paddingBottom"
                  onChange={onChangeFooterStyle}
                  value={footerData?.style?.paddingBottom}
                  label={intl.formatMessage({ id: "paddingBottom" })}
                  variant="outlined"
                  width="100%"
                  margin="normal"
                  type="number"
                  size="small"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">px</InputAdornment>
                    ),
                  }}
                  required
                />
                {/* <InputError show={errors.deskHeight.value}>{errors.deskHeight.text}</InputError> */}
              </InputsContainer>
              <InputsContainer width="45%">
                <StyledTextfield
                  id="gap"
                  name="gap"
                  onChange={onChangeFooterStyle}
                  value={footerData?.style?.gap}
                  label={intl.formatMessage({ id: "gap" })}
                  variant="outlined"
                  width="100%"
                  margin="normal"
                  type="number"
                  size="small"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">px</InputAdornment>
                    ),
                  }}
                  required
                />
                {/* <InputError show={errors.deskHeight.value}>{errors.deskHeight.text}</InputError> */}
              </InputsContainer>
              <InputsContainer width="45%">
                <StyledTextfield
                  id="minHeight"
                  name="minHeight"
                  onChange={onChangeFooterStyle}
                  value={footerData?.style?.minHeight}
                  label={intl.formatMessage({ id: "minHeight" })}
                  variant="outlined"
                  width="100%"
                  margin="normal"
                  type="number"
                  size="small"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">px</InputAdornment>
                    ),
                  }}
                  required
                />
                {/* <InputError show={errors.deskHeight.value}>{errors.deskHeight.text}</InputError> */}
              </InputsContainer>
              <InputsContainer width="45%">
                <StyledTextfield
                  id="maxHeight"
                  name="maxHeight"
                  onChange={onChangeFooterStyle}
                  value={footerData?.style?.maxHeight}
                  label={intl.formatMessage({ id: "maxHeight" })}
                  variant="outlined"
                  width="100%"
                  margin="normal"
                  type="number"
                  size="small"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">px</InputAdornment>
                    ),
                  }}
                  required
                />
                {/* <InputError show={errors.deskHeight.value}>{errors.deskHeight.text}</InputError> */}
              </InputsContainer>
              <InputsContainer width="45%">
                <StyledTextfield
                  id="footer_background_1"
                  name="background1"
                  onChange={onChangeFooterStyle}
                  value={footerData?.style?.background1}
                  label={intl.formatMessage({ id: "initial background color" })}
                  variant="outlined"
                  width="100%"
                  margin="normal"
                  size="small"
                  type="color"
                  required
                />
                <StyledTextfield
                  id="footer_background_2"
                  name="background2"
                  onChange={onChangeFooterStyle}
                  value={footerData?.style?.background2}
                  label={intl.formatMessage({ id: "end background color" })}
                  variant="outlined"
                  width="100%"
                  margin="normal"
                  size="small"
                  type="color"
                  required
                />
                {/* <InputError show={errors.deskHeight.value}>{errors.deskHeight.text}</InputError> */}
              </InputsContainer>
            </SectionWrapper>
          </Details>
        ) : null}
      </Content>
      <Modal open={openMenuItemModal} onClose={onCloseMenuItemModal}>
        <MenuItemModal
          addItem={handleAddMenu}
          editItem={handleEditMenu}
          handleClose={onCloseMenuItemModal}
          parentMenuIndex={parentMenuIndex}
          editData={editData}
        />
      </Modal>
      <Modal open={openActionItemModal} onClose={onCloseActionItemModal}>
        <MenuItemModal
          addItem={handleAddAction}
          editItem={handleEditAction}
          handleClose={onCloseActionItemModal}
          editData={editData}
        />
      </Modal>
      <Modal open={openSubmenuModal} onClose={onCloseSubmenuModal}>
        <SubmenuModal
          addItem={handleAddMenu}
          editItem={handleEditMenu}
          handleClose={onCloseSubmenuModal}
          parentMenuIndex={parentMenuIndex}
          editData={editSubmebus}
          editSubmenuData={editData}
          setEditSubmenuData={setEditData}
          data={navData?.items?.menus?.menuItems}
          handleDelete={handleDeleteMenu}
          handleOrder={handleOrder}
          openModal={setOpenMenuItemModal}
          setEditMenu={handleSetEditMenu}
          openSubmenuModal={setOpenSubmenuModal}
          setEditSubmenus={handleSetEditSubmenus}
        />
      </Modal>
    </Root>
  );
}
