import {useIntl} from "react-intl";
import {
    ContainerImgWithAlt,
    ImageContainer,
    InputContainer,
    InputData,
    StyledTextfield,
    Subtitle
} from "./StyledCourseComponent";
import translate from "../../providers/i18n/translate";
import SelectInput from "../inputs/SelectInput";
import React from "react";
import {Course} from "./Course";
import {ErrorMessage} from "./ErrorMessage";
import InputImage from "../inputs/inputImage";

const CourseMultimedia = (props) => {
    const intl = useIntl();

    return (
        <ContainerImgWithAlt>
            <Subtitle>{translate("course media")}</Subtitle>
            <InputData width="100%">
                <SelectInput
                    data={props.selectMediaType}
                    value={props.multimediaData?.type}
                    fieldlabel="value"
                    fieldvalue="value"
                    label={intl.formatMessage({id: "select src"})}
                    name="type"
                    onChange={props.handleChangeMultimediaAutocomplete}
                    width="100%"
                    margin="normal"
                    size="small"
                    required
                />
                <ErrorMessage
                    condition={()=>props.error.hasError && props.error.fieldsError.some(error => error.isIdentifiedAs(Course.ERROR_INVALID_MULTIMEDIA_DATA_TYPE))}
                    messageId={"type must not be empty"}
                />
            </InputData>
            {props.multimediaData?.type === "Image" && (
                <ContainerImgWithAlt>
                    <ImageContainer>
                        <InputImage
                            id="contentImage"
                            file={props.multimediaData?.url}
                            onChange={props.onChangeContentImage}
                            onReset={props.onResetContentImage}
                        />
                    </ImageContainer>
                    <InputContainer>
                        <StyledTextfield
                            id="multimediaAlt"
                            name="alter"
                            onChange={props.handleChangeMultimediaData}
                            value={props.multimediaData?.alter}
                            label={intl.formatMessage({id: "alternative text"})}
                            variant="outlined"
                            width="100%"
                            margin="normal"
                            size="small"
                        />
                        <ErrorMessage
                            condition={()=> props.error.hasError && props.error.fieldsError.some(error => error.isIdentifiedAs(Course.ERROR_INVALID_MULTIMEDIA_DATA_ALTER))}
                            messageId={"alter must not be empty"}
                        />
                    </InputContainer>
                    <InputContainer>
                        <StyledTextfield
                            id="multimediaEpigraph"
                            name="epigraph"
                            onChange={props.handleChangeMultimediaData}
                            value={props.multimediaData?.epigraph}
                            label={intl.formatMessage({id: "epigraph text"})}
                            variant="outlined"
                            width="100%"
                            margin="normal"
                            size="small"
                        />
                        <ErrorMessage
                            condition={()=> props.error.hasError && props.error.fieldsError.some(error => error.isIdentifiedAs(Course.ERROR_INVALID_MULTIMEDIA_DATA_EPIGRAPH))}
                            messageId={"epigraph must not be empty"}
                        />
                    </InputContainer>
                </ContainerImgWithAlt>
            )}
            {props.multimediaData?.type === "Video" && (
                <InputData width="100%">
                    <InputContainer>
                        <StyledTextfield
                            id="multimediaUrl"
                            name="url"
                            onChange={props.handleChangeMultimediaData}
                            value={props.multimediaData?.url}
                            label={intl.formatMessage({id: "link"})}
                            variant="outlined"
                            width="100%%"
                            margin="normal"
                            size="small"
                            required
                        />
                        <ErrorMessage
                            condition={()=> props.error.hasError && props.error.fieldsError.some(error => error.isIdentifiedAs(Course.ERROR_INVALID_MULTIMEDIA_DATA_URL))}
                            messageId={"url must not be empty"}
                        />
                    </InputContainer>
                </InputData>
            )}
        </ContainerImgWithAlt>
    )
}

export default CourseMultimedia
