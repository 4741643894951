import { ThemeContext } from "../../../providers/theme";
import { TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import styled from "styled-components";
import React from "react";
import SelectInput from "../../inputs/SelectInput";
import axios from "axios";
import { API_BACKEND } from "../../../config";
import { sectionTypeOptions } from "../../section/sectionTypes";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

const ModalContainer = styled.section`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 600px;
  height: auto;
  background-color: #fff;
  border-radius: 8px;
  padding: 20px;
`;

const InputGroup = styled.div`
  width: 100%;
  position: relative;
  flex-direction: column;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
`;

const InputError = styled.span`
  width: fit-content;
  height: ${(props) => (props.show ? "auto" : 0)};
  overflow: hidden;
  color: ${(props) => props.theme.palette.error};
  padding: ${(props) => (props.show ? "8px 10px" : 0)};
  opacity: ${(props) => (props.show ? 1 : 0)};
  border: ${(props) => props.theme.palette.error};
  background: ${(props) => `${props.theme.palette.error}10`};
  border-radius: 8px;
  margin-bottom: 10px;
  font-size: 0.875rem;
  transition: all 300ms ease-in-out;
`;

const Title = styled.h2`
  width: 100%;
  border-bottom: 1px solid
    ${(props) => (props.borderColor ? props.borderColor : "#000")};
  text-transform: uppercase;
  color: ${(props) => (props.color ? props.color : "#000")};
  margin: 0 0 10px 0;
`;

const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  padding: 5px;
  background-color: ${(props) => (props.bg ? props.bg : "#7A7A7A")};
  color: ${(props) => (props.color ? props.color : "#FFF")};
  border: none;
  outline: none;
  cursor: pointer;
  margin: 10px 0;
  transition: all 300ms ease-in-out;
  &:hover {
    transform: scale(1.1);
  }
`;

const StyledTextfield = styled(TextField)`
  width: 100%;
  @media (min-width: 1024px) {
    width: ${(props) => (props.width ? props.width : "inherit")};
  }
`;

// TODO: Change link section selection strategy

export default function MenuItemModal({editData, addItem, editItem, handleClose, parentMenuIndex }) {
  const intl = useIntl();
  const theme = React.useContext(ThemeContext);
  const [menuData, setMenuData] = useState(editData ? editData.menuData : {
    name: "",
    link: "",
    newTab: false,
    submenus: [],
  });

  const [pagesOpt, setPagesOpt] = useState(null);

  const [sectionsOpt, setSectionsOpt] = useState(null);

  const [linkType, setLinkType] = useState(editData ? editData.menuData?.linkType : null);

  const [page, setPage] = useState(editData ? editData.menuData?.page : null);

  const [section, setSection] = useState(editData ? editData.menuData?.section : null);

  const linkTypeOpt = [
    {
      label: intl.formatMessage({ id: "internal link" }),
      value: "internal",
    },
    {
      label: intl.formatMessage({ id: "external link" }),
      value: "external",
    },
    {
      label: intl.formatMessage({ id: "section link" }),
      value: "section",
    },
  ];

  const handleChangeCheck = (e) => {
    const { name, checked } = e.target;
    setMenuData((prevState) => ({
      ...prevState,
      [name]: checked,
    }));
  };

  const handleChangeLinkType = (name, value) => {

    setPage(null);
    setLinkType(value);
    setMenuData((prevState) => ({...prevState, linkType: value, link: ""}))
  };

  const handleChangePage = (name, value) => {
    setPage(value);
    setSectionsOpt(null);
    setMenuData((prevState) => ({
      ...prevState,
      link: value?.value,
    }))
  };

  const handleChangeSection = (name, value) => {
    setSection(value);
    setMenuData((prevState) => {
      return {
      ...prevState,
      link: value ? `#${value.value}` : null,
    }})
  };

  const onChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setMenuData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const [errors, setErrors] = React.useState({
    name: {
      value: false,
      text: intl.formatMessage({ id: "name must not be empty" }),
      validate: (value) => value == null || value === "",
    },
    linkType: {
      value: false,
      text: intl.formatMessage({ id: "link type must not be empty" }),
      validate: (value) => value == null || value === "",
    },
    link: {
      value: false,
      text: intl.formatMessage({ id: "link must not be empty" }),
      validate: (value) => value == null || value === "",
    },
  });

  const validateForm = () => {
    let foundError = false;
    const newErrors = {};
    Object.keys(errors).forEach((err) => {
      if (!foundError) {
        foundError = errors[err].validate(menuData[err]);
      }
      const val = errors[err].validate(menuData[err]);
      newErrors[err] = {
        ...errors[err],
        value: val,
      };
      setErrors({
        ...errors,
        ...newErrors,
      });
    });
    return !foundError;
  };


  const handleAddItem = () => {
    if (validateForm()) {
      addItem({
        ...menuData,
        linkType,
        page,
        section,
      });
      handleClose();
    }
  };

  const handleEditItem = () => {
    if (validateForm()) {
      editItem({
        ...menuData,
        linkType,
        page,
        section
      }, editData.parentMenuIndex, editData.index);
      handleClose();
    }
  }

  useEffect(() => {
    if (pagesOpt === null) {
      axios.get(`${API_BACKEND}/pages`).then((res) => {
        const resData = res.data;
        setPagesOpt(
          resData.map((page) => ({ label: page.name, value: page.fullpath })),
        );
      });
    }
  }, [pagesOpt]);

  return (
    <ModalContainer>
      <Title>Menu</Title>
      <InputGroup>
        <StyledTextfield
          id="name"
          name="name"
          onChange={onChange}
          value={menuData?.name}
          label={intl.formatMessage({ id: "name" })}
          variant="outlined"
          width="100%"
          margin="normal"
          size="small"
          required
        />
          <InputError show={errors.name.value}>{errors.name.text}</InputError>
      </InputGroup>
      <InputGroup>
      <SelectInput
        data={linkTypeOpt}
        value={linkType}
        fieldlabel="label"
        fieldvalue="value"
        label={intl.formatMessage({ id: "select link type" })}
        name="linkType"
        onChange={handleChangeLinkType}
        width="100%"
        margin="normal"
        size="small"
        required
      />
          <InputError show={errors.linkType.value}>{errors.linkType.text}</InputError>
      </InputGroup>
      {pagesOpt !== null && linkType?.value === "internal" ? (
        <InputGroup>
          <SelectInput
            data={pagesOpt}
            value={page}
            fieldlabel="label"
            fieldvalue="value"
            label={intl.formatMessage({ id: "select page" })}
            name="page"
            onChange={handleChangePage}
            width="100%"
            margin="normal"
            size="small"
            required
          />
          <InputError show={errors.link.value}>{intl.formatMessage({id: "page must not be empty"})}</InputError>
        </InputGroup>
      ) : null}
      {linkType?.value === "section" ? (
      <InputGroup>
        <SelectInput
          data={sectionTypeOptions.map((sectionType) => ({
            label: sectionType.name,
            value: sectionType.id,
          }))}
          value={section}
          fieldlabel="label"
          fieldvalue="value"
          label={intl.formatMessage({ id: "select section" })}
          name="section"
          onChange={handleChangeSection}
          width="100%"
          margin="normal"
          size="small"
          required
        />
          <InputError show={errors.link.value}>{intl.formatMessage({id: "section must not be empty"})}</InputError>
        </InputGroup>
      ) : null}
      {linkType?.value === "external" ? (
        <>
          <InputGroup>
          <StyledTextfield
            id="link"
            name="link"
            onChange={onChange}
            value={menuData?.link}
            label={intl.formatMessage({ id: "link" })}
            variant="outlined"
            width="100%"
            margin="normal"
            size="small"
            required
          />
          <InputError show={errors.link.value}>{errors.link.text}</InputError>
        </InputGroup>
          <FormControlLabel
            control={
              <Checkbox
                name="newTab"
                checked={menuData?.newTab}
                onChange={handleChangeCheck}
                inputProps={{ "aria-label": "new tab" }}
              />
            }
            label={intl.formatMessage({ id: "new tab" })}
          />
        </>
      ) : null}
      <Button
        onClick={!!editData ? handleEditItem : handleAddItem}
        bg={theme.palette.accent}
        color={theme.palette.base}
      >
        {theme.icons.check}
      </Button>
    </ModalContainer>
  );
}
