import {Assertion} from "../../creationAssertion/Assertion";
import {AssertionsRunner} from "../../creationAssertion/AssertionsRunner";

export class Course {
    static ERROR_INVALID_TITLE= 'TITLE'
    static ERROR_INVALID_SLUG = 'SLUG'
    static ERROR_INVALID_TYPE = 'TYPE'
    static ERROR_INVALID_STATUS = 'STATUS'
    static ERROR_INVALID_TAGS = 'TAGS'
    static ERROR_INVALID_RESPONSIBLE_EMAIL = 'EMAIL'
    static ERROR_INVALID_MULTIMEDIA_DATA_TYPE= 'MULTIMEDIA_DATA_TYPE'
    static ERROR_INVALID_MULTIMEDIA_DATA_ALTER = 'MULTIMEDIA_DATA_ALTER'
    static ERROR_INVALID_MULTIMEDIA_DATA_EPIGRAPH = 'MULTIMEDIA_DATA_EPIGRAPH'
    static ERROR_INVALID_MULTIMEDIA_DATA_URL = 'MULTIMEDIA_DATA_URL'
    static ERROR_INVALID_CARD_MULTIMEDIA_DATA_ALTER = 'CARD_MULTIMEDIA_DATA_ALTER'
    static ERROR_INVALID_CARD_MULTIMEDIA_DATA_EPIGRAPH = 'CARD_MULTIMEDIA_DATA_EPIGRAPH'

    static assertValidTitle(title) {
        return Assertion.for(
            title,
            Course.ERROR_INVALID_TITLE,
            () => !(title === undefined || title === null || title.trim().length === 0),
            'title must not be empty');
    }
    static assertValidSlug(slug) {
        return Assertion.for(
            slug,
            Course.ERROR_INVALID_SLUG,
            () => !(slug === undefined || slug === null || slug.trim().length === 0),
            'slug must not be empty');
    }

    static assertValidType(type) {
        return Assertion.for(
            type,
            Course.ERROR_INVALID_TYPE,
            () => !(type === undefined || type === null || type.length === 0),
            'type must not be empty');
    }

    static assertValidStatus(status) {
        return Assertion.for(
            status,
            Course.ERROR_INVALID_STATUS,
            () => !(status === undefined || status === null || status.length === 0),
            'status must not be empty');
    }

    static assertValidResponsibleEmail(responsibleEmails) {
        return Assertion.for(
            responsibleEmails,
            Course.ERROR_INVALID_RESPONSIBLE_EMAIL,
            () => !(responsibleEmails === undefined || responsibleEmails === null || responsibleEmails.length === 0),
            'responsibleEmails must not be empty');
    }

    static creteForForm(formData) {
        AssertionsRunner.assertAll([
            this.assertValidTitle(formData.title),
            this.assertValidSlug(formData.slug),
            this.assertValidType(formData.type),
            this.assertValidStatus(formData.status),
            this.assertValidResponsibleEmail(formData.responsibleEmails),
            Assertion.for(
                formData.tags,
                Course.ERROR_INVALID_TAGS,
                () => !(formData.tags === undefined || formData.tags === null || formData.tags.length === 0),
                'tags must not be empty'),
            Assertion.for(
                formData.multimediaData.type,
                Course.ERROR_INVALID_MULTIMEDIA_DATA_TYPE,
                () => !(formData.multimediaData.type === undefined || formData.multimediaData.type === null || (formData.multimediaData.type !== 'Image' && formData.multimediaData.type !== 'Video')),
                'type must not be empty'),
            Assertion.for(
                formData.multimediaData.alter,
                Course.ERROR_INVALID_MULTIMEDIA_DATA_ALTER,
                () => !(formData.multimediaData.type === 'Image' && (formData.multimediaData.alter === undefined || formData.multimediaData.alter === null ||  formData.multimediaData.alter.trim().length === 0)),
                'alter must not be empty'),
            Assertion.for(
                formData.multimediaData.epigraph,
                Course.ERROR_INVALID_MULTIMEDIA_DATA_EPIGRAPH,
                () => !(formData.multimediaData.type === 'Image' && (formData.multimediaData.epigraph === undefined || formData.multimediaData.epigraph === null || formData.multimediaData.epigraph.trim().length === 0)),
                'epigraph must not be empty'),
            Assertion.for(
                formData.multimediaData.url,
                Course.ERROR_INVALID_MULTIMEDIA_DATA_URL,
                () => !(formData.multimediaData.type === 'Video' && (formData.multimediaData.url === undefined || formData.multimediaData.url === null ||  formData.multimediaData.url.trim().length === 0)),
                'url must not be empty'),
            Assertion.for(
                formData.cardMultimediaData.alter,
                Course.ERROR_INVALID_CARD_MULTIMEDIA_DATA_ALTER,
                () => !(formData.cardMultimediaData.type === 'Image' &&  (formData.cardMultimediaData.alter === undefined || formData.cardMultimediaData.alter === null || formData.cardMultimediaData.alter.trim().length === 0)),
                'alter must not be empty'),
            Assertion.for(
                formData.cardMultimediaData.epigraph,
                Course.ERROR_INVALID_CARD_MULTIMEDIA_DATA_EPIGRAPH,
                () => !(formData.cardMultimediaData.type === 'Image'  && (formData.cardMultimediaData.epigraph === undefined || formData.cardMultimediaData.epigraph === null || formData.cardMultimediaData.epigraph.trim().length === 0)),
                'epigraph must not be empty'),
        ]);

        if(formData.multimedia !== undefined && 'id' in formData.multimediaData){
            delete formData.multimediaData.id
        }

        if(formData.cardMultimedia !== undefined && 'id' in formData.cardMultimediaData){
            delete formData.cardMultimediaData.id
        }

        const title = formData.title
        const subTitle = formData.subTitle
        const slug = formData.slug
        const resume = formData.resume
        const duration = formData.duration
        const startDate = formData.startDate // puede ser null
        const price = formData.price
        const responsible = formData.responsible
        const responsibleEmails = formData.responsibleEmails
        const status = formData.status
        const outstanding = formData.outstanding
        const type = formData.type
        const multimediaData = formData.multimediaData
        const cardMultimediaData = formData.cardMultimediaData
        const tags = formData.tags
        const curriculum = formData.data.curriculum
        const banner = formData.data.banner
        const multimedia = formData.multimedia
        const cardMultimedia = formData.cardMultimedia
        const userData = formData.userData

        return new this(title, subTitle, slug, resume, duration, startDate, price, responsible, responsibleEmails, status, outstanding,
            type, multimediaData, cardMultimediaData, tags, curriculum, banner, multimedia, cardMultimedia, userData)
    }

    constructor(title, subTitle, slug, resume, duration, startDate, price, responsible, responsibleEmails, status, outstanding,
                type, multimediaData, cardMultimediaData, tags, curriculum, banner, multimedia, cardMultimedia, userData) {
        this._title = title;
        this._subTitle = subTitle;
        this._slug = slug;
        this._resume = resume;
        this._duration = duration;
        this._startDate = startDate;
        this._price = price;
        this._responsible = responsible;
        this._responsibleEmails = responsibleEmails;
        this._status = status;
        this._outstanding = outstanding;
        this._type = type;
        this._multimediaData = multimediaData;
        this._cardMultimediaData = cardMultimediaData;
        this._tags = tags;
        this._curriculum = curriculum;
        this._banner = banner;
        this._multimedia = multimedia;
        this._cardMultimedia = cardMultimedia;
        this._userData = userData;
    }

    get userData() {
        return this._userData;
    }

    get title() {
        return this._title;
    }

    get subTitle() {
        return this._subTitle;
    }

    get slug() {
        return this._slug;
    }

    get resume() {
        return this._resume;
    }

    get duration() {
        return this._duration;
    }

    get startDate() {
        return this._startDate;
    }

    get price() {
        return this._price;
    }

    get responsible() {
        return this._responsible;
    }

    get responsibleEmails() {
        return this._responsibleEmails;
    }

    get status() {
        return this._status;
    }

    get outstanding() {
        return this._outstanding;
    }

    get type() {
        return this._type;
    }

    get multimediaData() {
        return this._multimediaData;
    }

    get cardMultimediaData() {
        return this._cardMultimediaData;
    }

    get tags() {
        return this._tags;
    }

    get curriculum() {
        return this._curriculum;
    }

    get banner() {
        return this._banner;
    }

    get multimedia() {
        return this._multimedia;
    }

    get cardMultimedia() {
        return this._cardMultimedia;
    }

    hasTitle(title) {
        return this.title === title
    }

    createDataToSend() {
        const dataToSend = {
            banner: this.banner,
            curriculum: this.curriculum
        }
        return JSON.stringify(dataToSend)
    }

    createFormData(){
        const formData = new FormData()
        formData.append("title", this.title);
        formData.append("subTitle", this.subTitle);
        formData.append("slug", this.slug);
        formData.append("resume", this.resume);
        formData.append("duration", this.duration);
        formData.append("price", this.price);
        formData.append("startDate", this.startDate);
        formData.append("responsible", this.responsible);
        formData.append("responsibleEmails", this.responsibleEmails.join(';'));
        formData.append("user", this.userData?.id);
        formData.append("type", this.type ? this.type.toString() : "");
        formData.append("status", this.status ? this.status.toString() : "");
        formData.append("outstanding", this.outstanding ? "1" : "0");
        formData.append("tags", this?.tags.join(','));
        formData.append("data", this.createDataToSend());
        formData.append("multimediaData", JSON.stringify(this.multimediaData));
        formData.append("cardMultimediaData", JSON.stringify(this.cardMultimediaData));
        formData.append("multimedia", this.multimedia);
        formData.append("cardMultimedia", this.cardMultimedia);

        return formData
    }
}
