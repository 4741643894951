import React from "react";
import styled from 'styled-components';
import translate from "../../../providers/i18n/translate";
import Row from "./Row";
import HeaderCell from "./HeaderCell";
import OrderButton from "./OrderButton";


const Root = styled.thead`
    position: sticky;
    top: 0;
    width: 100%;
    vertical-align: middle;
`



const Head = ({columnsTable, onOrder}) => {

    const handleOrder = (name, order) => {
        onOrder(name, order);
    }

    return (
        <Root>
            <Row>
                {
                    columnsTable && columnsTable.map((ct, index) =>
                        <HeaderCell key={`headercell_${index}`} width={`${ct.width}`} hidden={ct.hidden} hiddenMobile={(ct.mobile !== null && ct.mobile !== undefined) ? !ct.mobile : false} align={ct.align} color={ct.color}>
                            <span>{ct.title}</span>
                            {
                                ct.order && <OrderButton name={ct.field} action={handleOrder} />
                            }
                        </HeaderCell>   
                    )
                }
            </Row>
        </Root>
    );
}

export default Head;