import { loadingConstants } from "./loadingConstants";

const initialState = { 
  open: false,
  progress: 0
};

export function loading(state = initialState, action) {
  switch (action.type) {
    case loadingConstants.WAIT:
      return {
        type: loadingConstants.WAIT,
        open:true,
        progress: 0
      };
    case loadingConstants.ERROR:
      return {
        type: loadingConstants.ERROR,
        open:true
      };
    case loadingConstants.PROGRESS:
      return {
        type: loadingConstants.PROGRESS,
        open:true,
        progress: action.progress
      };
    case loadingConstants.READY:
      return {
        type: loadingConstants.READY,
        open:false
      };
    default:
      return state
  }
}