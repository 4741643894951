const API_FRONTEND= process.env.REACT_APP_FRONTEND
const API_BACKOFFICE= process.env.REACT_APP_BACKOFFICE
const API_BACKEND= process.env.REACT_APP_API_BACKEND
const API_USERMANAGER= process.env.REACT_APP_API_USERMANAGER
const API_USERMANAGER_SECRET= process.env.REACT_APP_API_USERMANAGER_SECRET
const MOLE_URL= process.env.REACT_APP_MOLE_URL

export {
    API_FRONTEND,
    API_BACKOFFICE,
    API_BACKEND,
    API_USERMANAGER,
    API_USERMANAGER_SECRET,
    MOLE_URL
}
