export const sectionTypeOptions = [
  {
    id: "heroBanner",
    name: "heroBanner",
  },
  {
    id: "heroBannerBigText",
    name: "heroBannerBigText",
  },
  {
    id: "heroBannerInstitucional",
    name: "heroBannerInstitucional",
  },
  {
    id: "heroBannerBigTextMobImages",
    name: "heroBannerBigTextMobImages",
  },
  {
    id: "heroBannerHalf",
    name: "heroBannerHalf",
  },
  {
    id: "basicTestimony",
    name: "basicTestimony",
  },
  {
    id: "bookWidget",
    name: "bookWidget",
  },
  {
    id: "bookWidgetV2",
    name: "bookWidgetV2",
  },
  {
    id: "textSection",
    name: "textSection",
  },
  {
    id: "textSectionV2",
    name: "textSectionV2",
  },
  {
    id: "textSectionV3",
    name: "textSectionV3",
  },
  {
    id: "textSectionV4",
    name: "textSectionV4",
  },
  {
    id: "textSectionOutlined",
    name: "textSectionOutlined",
  },
  {
    id: "twoColumnsPubs",
    name: "twoColumnsPubs",
  },
  {
    id: "twoColumnsPubsV2",
    name: "twoColumnsPubsV2",
  },
  {
    id: "twoColumnsPubsV3",
    name: "twoColumnsPubsV3",
  },
  {
    id: "pubsInstitucional",
    name: "pubsInstitucional",
  },
  {
    id: "pubsUlu",
    name: "pubsUlu",
  },
  {
    id: "pubsGolf",
    name: "pubsGolf",
  },
  {
    id: "pubsAgro",
    name: "pubsAgro",
  },
  {
    id: "newsletter",
    name: "newsletter",
  },
  {
    id: "newsletterV2",
    name: "newsletterV2",
  },
  {
    id: "ampMap",
    name: "ampMap",
  },
  {
    id: "ampGalleryCarousel",
    name: "ampGalleryCarousel",
  },
  {
    id: "ampGalleryCarouselV2",
    name: "ampGalleryCarouselV2",
  },
  {
    id: "cardsBand",
    name: "cardsBand",
  },
  {
    id: "cardsBandV2",
    name: "cardsBandV2",
  },
  {
    id: "cardsBandV3",
    name: "cardsBandV3",
  },
  {
    id: "cardsBandV4",
    name: "cardsBandV4",
  },
  {
    id: "cardsBandV5",
    name: "cardsBandV5",
  },
  {
    id: "cardsBandV6",
    name: "cardsBandV6",
  },
  {
    id: "cardsBandV7",
    name: "cardsBandV7",
  },
  {
    id: "cardsBandV8",
    name: "cardsBandV8",
  },
  {
    id: "cardsBandV9",
    name: "cardsBandV9",
  },
  {
    id: "cardsBandBottomArrows",
    name: "cardsBandBottomArrows",
  },
  {
    id: "cardsBandCardsBottomArrows",
    name: "cardsBandCardsBottomArrows",
  },
  {
    id: "cardsGrid",
    name: "cardsGrid",
  },
  {
    id: "cardsGridV2",
    name: "cardsGridV2",
  },
  {
    id: "heroImage",
    name: "heroImage",
  },
  {
    id: "heroImageV2",
    name: "heroImageV2",
  },
  {
    id: "heroImageRoom",
    name: "heroImageRoom",
  },
  {
    id: "lodgingDescription",
    name: "lodgingDescription",
  },
  {
    id: "lodgingDescriptionV2",
    name: "lodgingDescriptionV2",
  },
  {
    id: "twoColumnsCard",
    name: "twoColumnsCard",
  },
  {
    id: "twoColumnsCardV2",
    name: "twoColumnsCardV2",
  },
  {
    id: "gridSection",
    name: "gridSection",
  },
  {
    id: "menuPresenter",
    name: "menuPresenter",
  },
  {
    id: "book",
    name: "book",
  },
  {
    id: "divider",
    name: "divider",
  },
  {
    id: "dividerWithTransition",
    name: "dividerWithTransition",
  },
  {
    id: "iframeCloudBeds",
    name: "iframeCloudBeds",
  },
  {
    id: "iframePowerBI",
    name: "iframePowerBI",
  },
  {
    id: "twoColumnsBorderless",
    name: "twoColumnsBorderless",
  },
  {
    id: "twoColumnsBorderlessV2",
    name: "twoColumnsBorderlessV2",
  },
  {
    id: "twoColumnsBorderlessV3",
    name: "twoColumnsBorderlessV3",
  },
  {
    id: "twoColumnsV2",
    name: "twoColumnsV2",
  },
  {
    id: "twoColumnsWideImage",
    name: "twoColumnsWideImage",
  },
  {
    id: "fourColumnFullText",
    name: "fourColumnFullText",
  },
  {
    id: "formSection",
    name: "formSection",
  },
  {
    id: "formSectionV2",
    name: "formSectionV2",
  },
  {
    id: "formWorkWithUs",
    name: "formWorkWithUs",
  },
  {
    id: "formWorkWithUsV2",
    name: "formWorkWithUsV2",
  },
  {
    id: "formWorkWithUsV3",
    name: "formWorkWithUsV3",
  },
  {
    id: "formContactUsV1",
    name: "formContactUsV1",
  },
  {
    id: "mapSection",
    name: "mapSection",
  },
  {
    id: "chatButton",
    name: "chatButton",
  },
  {
    id: "contactButtons",
    name: "contactButtons",
  },
  {
    id: "contactInfo",
    name: "contactInfo",
  },
  {
    id: "twoColumnsText",
    name: "twoColumnsText",
  },
  {
    id: "iconsSection",
    name: "iconsSection",
  },
  {
    id: "twoColumnsCarousel",
    name: "twoColumnsCarousel",
  },
  {
    id: "twoColumnsTextCarousel",
    name: "twoColumnsTextCarousel",
  },
  {
    id: "hoverCardsSectors",
    name: "hoverCardsSectors",
  },
  {
    id: "servicesCardsSection",
    name: "servicesCardsSection",
  },
  {
    id: "servicesCardsSectionHome",
    name: "servicesCardsSectionHome",
  },
  {
    id: "textButtonSlider",
    name: "textButtonSlider",
  },
  {
    id: "eventsTimeline",
    name: "eventsTimeline",
  },
  {
    id: "eventsTimelineV2",
    name: "eventsTimelineV2",
  },
  {
    id: "stackedImagesSlider",
    name: "stackedImagesSlider",
  },
  {
    id: "stackedImagesSliderV2",
    name: "stackedImagesSliderV2",
  },
  {
    id: "stackedImagesSliderV3",
    name: "stackedImagesSliderV3",
  },
  {
    id: "presentationComponent",
    name: "presentationComponent",
  },
  {
    id: "twoColumnsImageAmenities",
    name: "twoColumnsImageAmenities",
  },
  {
    id: "pubsCardsBand",
    name: "pubsCardsBand",
  },
  {
    id: "welcomePopup",
    name: "welcomePopup",
  },
  {
    id: "faqAccordion",
    name: "faqAccordion",
  },
  {
    id: "sponsorView",
    name: "sponsorView",
  },
  {
    id: "multimediaGallery",
    name: "multimediaGallery"
  },
  {
    id: "middleTextBannerView",
    name: "middleTextBannerView"
  },
  {
    id: "golfCourtView",
    name: "golfCourtView"
  },
  {
    id: "dinamicTable",
    name: "dinamicTable"
  },

];
