import React from "react";
import styled from "styled-components";
import { ThemeContext } from "../../../../providers/theme";

const Root = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 80px;
    height: 80px;
    padding-top: 10px;
    box-shadow: 0 0 1px 2px rgba(0, 0, 0, 0.3);
    margin: 5px;
    background-color: rgba(0,0,0,0.05);
`

const Edit = styled.button`
  position: absolute;
  top: 5px;
  right: 25px;
  svg {
    font-size: 1rem;
  }
  background: transparent;
  border: none;
  cursor: pointer;
`;

const Icon = styled.div`
    width: 50px;
    height: 50px;
    svg{
      width: 100%;
      height: 100%;
    }
`

const SocialIcon = ({icon, index, handleDelete, handleEdit}) => {

    const theme = React.useContext(ThemeContext);

    return (
        <Root >
            <Icon onClick={handleEdit}>{theme.icons[icon]}</Icon>
            <Edit data-index={index} onClick={handleEdit}>{theme.icons.edit}</Edit>
            <Delete data-index={index} onClick={handleDelete}>{theme.icons.close}</Delete>
        </Root>
    );
}

const Delete = styled.button`
    position: absolute;
    top: 5px;
    right: 5px;
    svg {
        font-size: 1rem;
    }
    background: transparent;
    border: none;
    cursor: pointer;
`

export default SocialIcon;
