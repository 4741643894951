import {InputError} from "./StyledCourseComponent";
import React from "react";
import {useIntl} from "react-intl";

export function ErrorMessage(props) {
    const intl = useIntl();

    return (
        <InputError show={props.condition()}>
            {intl.formatMessage({id: props.messageId})}
        </InputError>);
}
