import { useState, useEffect } from "react";
import { decodeToken, isTokenExpired } from "./jwt";

export function useJwt(userJwt) {
  const [isExpired, setIsExpired] = useState(false);
  const [decodedToken, setDecodedToken] = useState(null);

  useEffect(() => {
    evaluateToken(userJwt);
  }, [userJwt]);

  const evaluateToken = (token) => {
    setDecodedToken(decodeToken(token));
    setIsExpired(isTokenExpired(token));
  };

  return { isExpired, decodedToken, reEvaluateToken: evaluateToken };
}