import {
    ActionButton,
    Actions,
    Container,
    CourseDescriptions,
    Description,
    GalleryContainer,
    Header,
    InputContainer,
    InputData,
    InputRowContainer,
    MetaData,
    Root,
    Subtitle,
    Title,
    StyledTextfield
} from "./StyledCourseComponent";
import translate from "../../providers/i18n/translate";
import {ErrorMessage} from "./ErrorMessage";
import SelectInput from "../inputs/SelectInput";
import {API_BACKEND} from "../../config";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DateAdapter from "@mui/lab/AdapterMoment";
import DateTimePicker from "@mui/lab/DateTimePicker";
import EmailInput from "../inputs/EmailInput";
import {Checkbox, FormControlLabel} from "@mui/material";
import BasicEditor from "../textEditors/basicEditor";
import InputGallery from "../inputGallery";
import Accordion from "../accordion";
import React from "react";
import CardImageCourse from "./CardImageCourse";
import {Course} from "./Course";
import CourseMultimedia from "./CourseMultimedia";
import {useIntl} from "react-intl";
import {useNavigate} from "react-router-dom";

export function CourseView(props) {
    const intl = useIntl();
    let navigate = useNavigate();

    const parseImages = ( toParse) => {
        return toParse.map((elem) => {
            return {
                id: elem.id,
                url: elem.img,
                alt: elem.alt,
                order: elem.order,
            };
        });
    };

    const back = () => {
        navigate(-1);
    };

    const selectMediaType = [
        {
            label: intl.formatMessage({id: "image from file"}),
            value: "Image",
        },
        {
            label: intl.formatMessage({id: "video from URL"}),
            value: "Video",
        },
    ];

    return (
        <Root>
            <Header>
                <Title>
                    {props.title}
                </Title>
                <Actions>
                    <ActionButton onClick={props.loadImages}>
                        {translate("apply")}
                    </ActionButton>
                    <ActionButton onClick={back}>{translate("cancel")}</ActionButton>
                </Actions>
            </Header>
            <Container>
                <MetaData>
                    <Subtitle>{translate("General data")}</Subtitle>
                    <InputRowContainer>
                        <CardImageCourse
                            cardMultimediaData={props.data.cardMultimediaData}
                            onChange={props.onChangeCardImage}
                            onReset={props.onResetCardImage}
                            handleChangeCardMultimediaData={props.handleChangeCardMultimediaData}
                            error={props.error}
                        />
                        <InputData width="100%" justify="space-between">
                            <InputContainer>
                                <StyledTextfield
                                    id="title"
                                    name="title"
                                    onChange={props.handleChange}
                                    value={props.data.title}
                                    label={intl.formatMessage({id: "title"})}
                                    variant="outlined"
                                    width="100%"
                                    margin="dense"
                                    size="small"
                                    required
                                />
                                <ErrorMessage
                                    condition={()=>props.error.hasError && props.error.fieldsError.some(error => error.isIdentifiedAs(Course.ERROR_INVALID_TITLE))}
                                    messageId={"title must not be empty"}
                                />
                            </InputContainer>
                            <StyledTextfield
                                id="subTitle"
                                name="subTitle"
                                onChange={props.handleChange}
                                value={props.data.subTitle}
                                label={intl.formatMessage({id: "subtitle"})}
                                variant="outlined"
                                width="100%"
                                margin="dense"
                                size="small"
                                required
                            />
                            <InputContainer width="20%">
                                <StyledTextfield
                                    id="slug"
                                    name="slug"
                                    onChange={props.handleChange}
                                    value={props.data.slug}
                                    label={intl.formatMessage({id: "slug"})}
                                    variant="outlined"
                                    margin="dense"
                                    size="small"
                                    required
                                />
                                <ErrorMessage
                                    condition={()=> props.error.hasError && props.error.fieldsError.some(error => error.isIdentifiedAs(Course.ERROR_INVALID_SLUG))}
                                    messageId={"slug must not be empty"}
                                />
                            </InputContainer>
                            <InputContainer width="20%">
                                <SelectInput
                                    dataurl={`${API_BACKEND}/coursetype`}
                                    value={props.data.type}
                                    fieldlabel="value"
                                    fieldvalue="id"
                                    label={intl.formatMessage({id: "course type"})}
                                    name="type"
                                    onChange={props.handleChangeAutocomplete}
                                    width="100%"
                                    margin="normal"
                                    size="small"
                                    required
                                />
                                <ErrorMessage
                                    condition={()=> props.error.hasError && props.error.fieldsError.some(error => error.isIdentifiedAs(Course.ERROR_INVALID_TYPE))}
                                    messageId={"type must not be empty"}
                                />
                            </InputContainer>
                            <InputContainer width="20%">
                                <SelectInput
                                    dataurl={`${API_BACKEND}/coursestatus`}
                                    value={props.data.status}
                                    fieldlabel="value"
                                    fieldvalue="id"
                                    label={intl.formatMessage({id: "status"})}
                                    name="status"
                                    onChange={props.handleChangeAutocomplete}
                                    width="100%"
                                    margin="normal"
                                    size="small"
                                    required
                                />
                                <ErrorMessage
                                    condition={()=> props.error.hasError && props.error.fieldsError.some(error => error.isIdentifiedAs(Course.ERROR_INVALID_STATUS))}
                                    messageId={"status must not be empty"}
                                />
                            </InputContainer>
                            <InputContainer width="25%">
                                <SelectInput
                                    multiple
                                    dataurl={`${API_BACKEND}/tags/list`}
                                    value={props.data.tags}
                                    fieldlabel="name"
                                    fieldvalue="id"
                                    label={intl.formatMessage({id: "tags"})}
                                    name="tags"
                                    onChange={props.handleChangeAutocomplete}
                                    width="100%"
                                    margin="normal"
                                    size="small"
                                    required
                                />
                                <ErrorMessage
                                    condition={()=> props.error.hasError && props.error.fieldsError.some(error => error.isIdentifiedAs(Course.ERROR_INVALID_TAGS))}
                                    messageId={"tags must not be empty"}
                                />
                            </InputContainer>

                            <StyledTextfield
                                id="price"
                                name="price"
                                onChange={props.handleChange}
                                value={props.data.price}
                                label={intl.formatMessage({id: "price"})}
                                variant="outlined"
                                width="49%"
                                margin="dense"
                                size="small"
                                required
                            />
                            <StyledTextfield
                                id="duration"
                                name="duration"
                                onChange={props.handleChange}
                                value={props.data.duration}
                                label={intl.formatMessage({id: "duration"})}
                                variant="outlined"
                                width="49%"
                                margin="dense"
                                size="small"
                                required
                            />
                            <LocalizationProvider dateAdapter={DateAdapter}>
                                <DateTimePicker
                                    renderInput={(props) => (
                                        <StyledTextfield
                                            required
                                            margin="normal"
                                            size="small"
                                            width="49%"
                                            {...props}
                                        />
                                    )}
                                    label={intl.formatMessage({id: "starting date"})}
                                    value={props.data.startDate}
                                    onChange={props.handleChangeDate}
                                />
                            </LocalizationProvider>
                            <StyledTextfield
                                id="responsible"
                                name="responsible"
                                onChange={props.handleChange}
                                value={props.data.responsible}
                                label={intl.formatMessage({id: "person in charge"})}
                                variant="outlined"
                                width="49%"
                                margin="dense"
                                size="small"
                                required
                            />
                            <InputContainer width="85%">
                                <EmailInput
                                    id="responsibleEmails"
                                    name="responsibleEmails"
                                    label={intl.formatMessage({id: "person in charge emails"})}
                                    onChange={props.handleChangeEmails}
                                    editData={props.data.responsibleEmails}
                                    placeholder="emails"
                                />
                                <ErrorMessage
                                    condition={()=> props.error.hasError && props.error.fieldsError.some(error => error.isIdentifiedAs(Course.ERROR_INVALID_RESPONSIBLE_EMAIL))}
                                    messageId={"responsibleEmails must not be empty"}
                                />
                            </InputContainer>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        name="outstanding"
                                        checked={props.data.outstanding}
                                        onChange={props.handleChangeCheck}
                                        inputProps={{"aria-label": "controlled"}}
                                    />
                                }
                                label={intl.formatMessage({id: "outstanding"})}
                            />
                        </InputData>
                    </InputRowContainer>
                    <CourseDescriptions>
                        <Description>
                            <BasicEditor
                                name="description"
                                value={props.data.resume}
                                onChange={props.handleEditorDescription}
                                editorLoaded={props.editorLoaded}
                                maxContentHeight="150px"
                                minContentHeight="150px"
                                placeholder={intl.formatMessage({
                                    id: "insert description here...",
                                })}
                            />
                        </Description>
                    </CourseDescriptions>
                </MetaData>
                <CourseMultimedia
                    selectMediaType={selectMediaType}
                    multimediaData={props.data.multimediaData}
                    handleChangeMultimediaAutocomplete={props.handleChangeMultimediaAutocomplete}
                    onChangeContentImage={props.onChangeContentImage}
                    onResetContentImage={props.onResetContentImage}
                    handleChangeMultimediaData={props.handleChangeMultimediaData}
                    error={props.error}
                />
                <GalleryContainer>
                    <InputGallery
                        editData={
                            props.data?.data?.banner?.length > 0
                                ? parseImages(props.data.data.banner)
                                : null
                        }
                        ref={props.galleryRef}
                        forceColumn={false}
                        handleAction={props.submit}
                        withContent={false}
                    />
                </GalleryContainer>
                <Accordion
                    title={intl.formatMessage({id: "curriculum"})}
                    data={props.curriculum} setData={props.setCurriculum}
                />
            </Container>
        </Root>)
}
