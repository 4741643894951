import styled from "styled-components";
import React from "react";
import { useIntl } from "react-intl";
import { TextField } from "@mui/material";
import translate from "../../../../providers/i18n/translate";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

const Root = styled.form`
  display: flex;
  flex-direction: column;
  width: 350px;
  height: auto;
  padding: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
`;

const StyledTextfield = styled(TextField)`
  width: 100%;
  @media (min-width: 1024px) {
    width: ${(props) => (props.width ? props.width : "inherit")};
  }
`;

const Title = styled.h4`
  font-size: 1.5rem;
  width: 100%;
  color: ${(props) => props.theme.palette.details};
  border-bottom: 1px solid ${(props) => props.theme.palette.details};
  height: auto;
  text-transform: uppercase;
`;

const NewInfo = ({ onClose, handleEdit, handleNew, editData }) => {

  const [data, setData] = React.useState(editData ? editData : {
    text: "",
    link: "",
    newTab: false,
  });

  const intl = useIntl();

  const handleChangeCheck = (e) => {
    const { name, checked } = e.target;
    setData({
      ...data,
      [name]: checked,
    });
  };


  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setData({
      ...data,
      [name]: value,
    });
  };

  const onNew = (e) => {
    e.preventDefault();
    handleNew({
      link: data.link,
      text: data.text,
      newTab: data.newTab,
    });
    onClose();
  };

  const onEdit = (e) => {
    e.preventDefault();
    handleEdit(editData.index,{
      link: data.link,
      text: data.text,
      newTab: data.newTab,
    });
    onClose();
  };

  return (
    <Root>
      <Title>{editData ? translate('edit') : translate("new")}</Title>
      <StyledTextfield
        id="text"
        name="text"
        onChange={handleChange}
        value={data.text}
        label={intl.formatMessage({ id: "text" })}
        variant="outlined"
        width="100%"
        margin="dense"
        size="small"
        required
      />
      <StyledTextfield
        id="link"
        name="link"
        onChange={handleChange}
        value={data.link}
        label={intl.formatMessage({ id: "link" })}
        variant="outlined"
        width="100%"
        margin="dense"
        size="small"
      />
        <FormControlLabel
          control={
            <Checkbox
              name="newTab"
              checked={data.newTab}
              onChange={handleChangeCheck}
              inputProps={{ "aria-label": "controlled invert" }}
            />
          }
          label={intl.formatMessage({ id: "new tab" })}
        />
      { editData ?
        <button onClick={onEdit}>{translate('edit')}</button>
        :
        <button onClick={onNew}>{translate('create')}</button>
      }
    </Root>
  );
};

export default NewInfo;
