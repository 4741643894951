import { TextField } from "@mui/material";
import axios from "axios";
import React from "react";
import { useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import { API_BACKEND } from "../../config";
import translate from "../../providers/i18n/translate";
import { ThemeContext } from "../../providers/theme";
import { bindActionCreators } from "redux";
import * as actionCreators from "../../store/action-creators";
import { alertConstants } from "../../store/reducers/messageBar/alertConstants";
import SelectInput from "../inputs/SelectInput";
import { sectionTypeOptions } from "./sectionTypes";
import { useAuth } from "../../providers/authentication";
import { decodeToken } from "../../hooks/jwt";
import { ColorPicker, useColor } from "react-color-palette";
import "react-color-palette/lib/css/styles.css";
import PaddingSelector from "../inputs/paddingSelector";

/* Styled components */
const Root = styled.form`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 800px;
  height: auto;
  background-color: #fff;
  border-radius: 8px;
  padding: 20px;
`;

const Container = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 10px 0;
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 45%;
  align-items: center;
`;

const Title = styled.h2`
  width: 100%;
  border-bottom: 1px solid
    ${(props) => (props.borderColor ? props.borderColor : "#000")};
  text-transform: uppercase;
  color: ${(props) => (props.color ? props.color : "#000")};
  margin: 0 0 10px 0;
`;

const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  padding: 5px;
  background-color: ${(props) => (props.bg ? props.bg : "#7A7A7A")};
  color: ${(props) => (props.color ? props.color : "#FFF")};
  border: none;
  outline: none;
  cursor: pointer;
  margin: 10px 0;
  transition: all 300ms ease-in-out;
  &:hover {
    transform: scale(1.1);
  }
`;

const CreateSection = ({ editData, page, handleClose }) => {
  const theme = React.useContext(ThemeContext);

  const intl = useIntl();

  const { token } = useAuth();

  const userData = decodeToken(token);

  const [pageList, setPageList] = React.useState(null);

  const [color, setColor] = useColor(
    "hex",
    editData?.backgroundColor ?? "#ffffff00"
  );

  const [data, setData] = React.useState(
    editData
      ? {
          ...editData,
          page: null,
          backgroundColor: editData.backgroundColor ?? "#ffffff00",
          padding: editData.padding ?? {
            paddingMob: { usePadding: false },
            paddingTab: { usePadding: false },
            paddingDesk: { usePadding: false },
          },
        }
      : {
          name: "",
          backgroundColor: "#ffffff00",
          type: null,
          data: null,
          order: "",
          page: null,
          padding: {
            paddingMob: { usePadding: false },
            paddingTab: { usePadding: false },
            paddingDesk: { usePadding: false },
          },
        }
  );

  const dispatch = useDispatch();

  const { alertMessage } = bindActionCreators(actionCreators, dispatch);

  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setData({
      ...data,
      [name]: value,
    });
  };

  const handlePaddingChange = (paddingName, name, value) => {
    setData({
      ...data,
      padding: {
        ...data.padding,
        [paddingName]: { ...data.padding[paddingName], [name]: value },
      },
    });
  };

  const handlePaddingCheckedChange = (paddingName, name, value) => {
    setData({
      ...data,
      padding: {
        ...data.padding,
        [paddingName]: value
          ? { ...data.padding[paddingName], [name]: value }
          : { usePadding: value },
      },
    });
  };

  const handleChangeAutocomplete = (name, value) => {
    setData({
      ...data,
      [name]: value,
    });
  };

  const handleAction = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("name", data.name);
    formData.append("order", data.order);
    formData.append("backgroundColor", color.hex);
    formData.append("page", data.page?.value);
    formData.append("user", userData?.id);
    formData.append("padding", JSON.stringify(data?.padding));
    if (editData) {
      formData.append("type", editData.type);
      axios
        .put(`${API_BACKEND}/sections/${editData.id}`, formData, {})
        .then((res) => {
          alertMessage(
            intl.formatMessage({ id: "section edited" }),
            alertConstants.INFO
          );
          handleClose();
        })
        .catch((error) => {
          alertMessage(error.response?.data?.msg, alertConstants.ERROR);
        });
    } else {
      formData.append("type", data.type?.value);
      axios
        .post(`${API_BACKEND}/sections`, formData, {})
        .then((res) => {
          alertMessage(
            intl.formatMessage({ id: "section created" }),
            alertConstants.INFO
          );
          handleClose();
        })
        .catch((error) => {
          alertMessage(error.response?.data?.msg, alertConstants.ERROR);
        });
    }
  };

  const getPagesList = () => {
    axios
      .get(`${API_BACKEND}/pages`)
      .then((res) => {
        setPageList(res.data);
        if (page) {
          const selectedPage = res.data?.find((p) => p.id == page);
          setData({
            ...data,
            page: {
              label: selectedPage.name,
              value: selectedPage.id,
            },
          });
        }
      })
      .catch((error) => {
        alertMessage(error.response?.data?.msg, alertConstants.ERROR);
      });
  };

  React.useEffect(() => {
    if (!pageList) {
      getPagesList();
    }
  }, []);

  return (
    <Root onSubmit={handleAction}>
      <Title>
        {editData ? translate("edit section") : translate("new section")}
      </Title>
      <Container>
        <InputContainer>
          {pageList && (
            <SelectInput
              data={pageList}
              value={data.page}
              fieldlabel="name"
              fieldvalue="id"
              label="page"
              name="page"
              onChange={handleChangeAutocomplete}
              width="100%"
              margin="normal"
              size="small"
              required
            />
          )}
          <TextField
            id="name"
            name="name"
            onChange={handleChange}
            value={data.name}
            label={intl.formatMessage({ id: "name" })}
            variant="outlined"
            margin="normal"
            size="small"
            required
            fullWidth
          />
          <TextField
            id="order"
            name="order"
            type="number"
            onChange={handleChange}
            value={data.order}
            label={intl.formatMessage({ id: "order" })}
            variant="outlined"
            margin="normal"
            size="small"
            required
            fullWidth
          />
          {!editData && (
            <SelectInput
              data={sectionTypeOptions}
              value={data.type}
              fieldlabel="name"
              fieldvalue="id"
              label="section type"
              name="type"
              onChange={handleChangeAutocomplete}
              margin="normal"
              size="small"
              required
              width="100%"
            />
          )}
        </InputContainer>
        <InputContainer>
          <h3>{translate("background color")}</h3>
          <ColorPicker
            width={250}
            height={150}
            color={color}
            onChange={setColor}
            alpha
            hideHSV
            hideRGB
            dark
          />
        </InputContainer>
      </Container>
      <PaddingSelector
        editPadding={data.padding.paddingMob}
        name="paddingMob"
        handlePaddingChange={handlePaddingChange}
        handlePaddingCheckedChange={handlePaddingCheckedChange}
      />
      <PaddingSelector
        editPadding={data.padding.paddingTab}
        name="paddingTab"
        handlePaddingChange={handlePaddingChange}
        handlePaddingCheckedChange={handlePaddingCheckedChange}
      />
      <PaddingSelector
        editPadding={data.padding.paddingDesk}
        name="paddingDesk"
        handlePaddingChange={handlePaddingChange}
        handlePaddingCheckedChange={handlePaddingCheckedChange}
      />

      <Button bg={theme.palette.accent} color={theme.palette.base}>
        {theme.icons.check}
      </Button>
    </Root>
  );
};

export default CreateSection;
