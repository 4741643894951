import styled from "styled-components";
import TextField from "@mui/material/TextField";

export const Root = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding: 20px;
`;
export const Header = styled.div`
    position: relative;
    display: flex;
    width: 100%;
    height: 50px;
    border-bottom: 1px solid black;
`;
export const Title = styled.h2`
    text-transform: uppercase;
`;
export const Subtitle = styled.h4`
    font-size: 1.5rem;
    width: 100%;
    color: ${(props) => props.theme.palette.details};
    border-bottom: 1px solid ${(props) => props.theme.palette.details};
`;
export const Actions = styled.div`
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    gap: 20px;
    align-items: center;
    justify-content: flex-end;
`;
export const ActionButton = styled.button`
    background: transparent;
    text-transform: capitalize;
    border: 1px solid rgba(0, 0, 0, 0.5);
    padding: 8px 10px;
    cursor: pointer;
    border-radius: 8px;

    &:hover {
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
    }
`;
export const Container = styled.div`
    display: grid;
    width: 100%;
    height: calc(100% - 50px);
    grid-template-columns: 0.7fr 0.3fr;
    grid-template-rows: 0.7fr 0.3fr;
    gap: 20px;
    padding: 20px 0;
`;
const Box = styled.div`
    width: 100%;
    height: calc(100% - 50px);
`;
export const MetaData = styled(Box)`
    grid-row: 2;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
`;
export const ContainerImgWithAlt = styled(Box)`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    grid-column: 2/4;
    width: auto;
    height: auto;
`;
export const InputRowContainer = styled.div`
    position: relative;
    display: flex;
    width: 100%;
    gap: 20px;
    @media (min-width: 1024px) {
        width: ${(props) => (
    props.width ? props.width : "100%"
)};
    }
`;
export const ImageContainer = styled.div`
    width: 300px;
    height: 300px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;
export const InputData = styled.div`
    display: flex;
    height: fit-content;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: ${(props) => (
    props.justify ? props.justify : "center")};
    width: 100%;
    @media (min-width: 1024px) {
        width: ${(props) => (
    props.width ? props.width : "inherit")};
    }
`;
export const InputError = styled.span`
    display: block;
    width: fit-content;
    height: ${(props) => (
    props.show ? "auto" : 0)};
    overflow: hidden;
    color: ${(props) => props.theme.palette.error};
    padding: ${(props) => (
    props.show ? "8px 10px" : 0)};
    opacity: ${(props) => (
    props.show ? 1 : 0)};
    border: ${(props) => props.theme.palette.error};
    background: ${(props) => `${props.theme.palette.error}10`};
    border-radius: 8px;
    font-size: 0.875rem;
    transition: all 300ms ease-in-out;
`;
export const GalleryContainer = styled(Box)`
    height: fit-content;
    grid-row: 1;
    grid-column: 1/3;
`;
export const CourseDescriptions = styled.div`
    position: relative;
    display: grid;
    width: 100%;
    height: auto;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
`;
export const Description = styled.div`
    position: relative;
    width: 100%;
    height: auto;
    grid-column: 1/3;
`;
export const InputContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: fit-content;
    @media (min-width: 1024px) {
        width: ${(props) => (
    props.width ? props.width : "100%")};
    }
`
export const StyledCourseComponent = styled(TextField)`
    width: 100%;
    @media (min-width: 1024px) {
        width: ${(props) => (props.width ? props.width : "100%")};
    }
`;

export const StyledTextfield = styled(TextField)`
  width: 100%;
  @media (min-width: 1024px) {
    width: ${(props) => (props.width ? props.width : "100%")};
  }
`;
