import React from "react";
import styled from "styled-components";
import { ThemeContext } from "../../../../providers/theme";
import MenuCard from "./menuCard";

const Root = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  height: auto;
`;

const AddMenu = styled.button`
  position: relative;
  color: ${(props) => props.theme.palette.principal};
  cursor: pointer;
  width: 100px;
  height: 100px;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.2);
  border: none;
  svg {
    width: 50px;
    height: 50px;
  }
  transition: all ease-in-out 100ms;
  &:hover {
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.5);
  }
`;

const MenuCards = styled.div`
  position: relative;
  display: flex;
  flex-direction: ${(props) => (props.forceColumn ? "column" : "row")};
  gap: ${(props) => (props.forceColumn ? "20px" : "50px")};
  align-items: ${(props) => (props.forceColumn ? "center" : "flex-start")};
  justify-content: flex-start;
  overflow-y: auto;
  flex-wrap: wrap;
  padding: 20px;
`;

const MenusContainer = ({
  handleDelete,
  handleOrder,
  openModal,
  openSubmenuModal,
  forceColumn,
  withContent,
  contentMediaType,
  data,
  setEditMenu,
  setEditSubmenus,
  submenu,
}) => {
  // TODO: Implement setEditMenu functionality
  const theme = React.useContext(ThemeContext);

  const handleDown = (index) => {
    const newData = [...data];
    const value = newData[index];
    const nextValue = newData[index + 1];
    newData[index + 1] = value;
    newData[index] = nextValue;
    handleOrder(newData);
  };

  const handleUp = (index) => {
    const newData = [...data];
    const value = newData[index];
    const prevValue = newData[index - 1];
    newData[index - 1] = value;
    newData[index] = prevValue;
    handleOrder(newData);
  };

  const handleEdit = (index, menuCardData) => {
    setEditMenu(index, menuCardData);
    openModal(true);
  };

  const handleSubmenu = (index, menu) => {
    setEditSubmenus(index, menu);
    openSubmenuModal(true);
  };

  return (
    <Root>
      <MenuCards forceColumn={forceColumn}>
        {data &&
          data.length > 0 &&
          data.map((menuCard, index) => (
            <MenuCard
              key={`media_${index}`}
              forceColumn={forceColumn}
              data={menuCard}
              handleDelete={handleDelete}
              handleUp={handleUp}
              handleDown={handleDown}
              handleEdit={handleEdit}
              handleSubmenu={setEditSubmenus ? handleSubmenu : undefined}
              first={index === 0}
              last={index === data.length - 1}
              order={index}
              withContent={withContent}
              contentMediaType={withContent ? contentMediaType : null}
              submenu={submenu}
            />
          ))}
        <AddMenu onClick={() => openModal(true)}>{theme.icons.add}</AddMenu>
      </MenuCards>
    </Root>
  );
};

export default MenusContainer;
