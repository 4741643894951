import React from "react";
import styled from "styled-components";
import SocialIcon from "./socialIcon";
import { Modal } from "@mui/material";
import NewSocialIcon from "./newSocialIcon";
import translate from "../../../../providers/i18n/translate";
import { ThemeContext } from "../../../../providers/theme";

const Root = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
`;

const Title = styled.div`
  position: relative;
  font-size: 1.2rem;
  width: 100%;
  color: ${(props) => props.theme.palette.details};
  border-bottom: 1px solid ${(props) => props.theme.palette.details};
  height: auto;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const ContainerSocialIcons = styled.div`
  width: 100%;
  height: auto;
  min-height: 100px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 20px;
  flex-wrap: wrap;
`;

const AddImage = styled.button`
  position: relative;
  color: ${(props) => props.theme.palette.principal};
  cursor: pointer;
  margin-right: 1rem;
  border: none;
  background: none;
`;

const SocialIconsSelector = ({socialIcons, setSocialIcons}) => {

  const theme = React.useContext(ThemeContext);
  const [open, setOpen] = React.useState(false);
  const [editSocialIconData, setEditSocialIconData] = React.useState(null);

  const deleteSocialIcon = (e) => {
    e.preventDefault();
    const {index} = e.currentTarget.dataset;
    const newSocialIcons = [...socialIcons];
    newSocialIcons.splice(index, 1);
    setSocialIcons([...newSocialIcons])
  };

  const addSocialIcon = (newSocialIcon) => {
    const newSocialIcons = [...socialIcons];
    newSocialIcons.push(newSocialIcon);
    setSocialIcons([...newSocialIcons]);
  };

  const editSocialIcon = (index, socialIcon) => {
    const newSocialIcons = [...socialIcons];
    newSocialIcons.splice(index, 1, {...socialIcon});
    setSocialIcons([...newSocialIcons]);
  }

  const onEdit = (e) => {
    e.preventDefault();
    const {index} = e.currentTarget.dataset;
    setEditSocialIconData({
      index,
      ...socialIcons[index]
    });
    setOpen(true);
  }

  const onOpen = (e) => {
    e.preventDefault();
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
    setEditSocialIconData(null);
  };

  return (
    <Root>
      <Title>
        {translate("Icons")}
        <AddImage onClick={onOpen}>
          {theme.icons.add}
        </AddImage>
      </Title>
      <ContainerSocialIcons>
        {socialIcons &&
          socialIcons.map((am, index) => (
            <SocialIcon
              key={`socialIcon_${index}`}
              icon={am.src}
              handleDelete={deleteSocialIcon}
              handleEdit={onEdit}
              index={index}
            />
          ))}
      </ContainerSocialIcons>
      <Modal open={open} onClose={onClose}>
        <NewSocialIcon selectedSocialIcons={socialIcons} editData={editSocialIconData} handleEdit={editSocialIcon} onClose={onClose} handleNew={addSocialIcon} />
      </Modal>
    </Root>
  );
};

export default SocialIconsSelector;
