import React from "react";
import styled from "styled-components";
import translate from "../../providers/i18n/translate";
import { ThemeContext } from "../../providers/theme";
import { useIntl } from "react-intl";
import { TextField } from "@mui/material";

/* Styled components */
const Root = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  background-color: #fff;
  border-radius: 8px;
  padding: 20px;
  max-height: 90vh;
  overflow-y: auto;
  @media (min-width: 1024px) {
    width: 600px;
  }
`;

const Title = styled.h2`
  width: 100%;
  border-bottom: 1px solid
    ${(props) => (props.borderColor ? props.borderColor : "#000")};
  text-transform: uppercase;
  color: ${(props) => (props.color ? props.color : "#000")};
  margin: 0 0 10px 0;
`;

const ButtonRow = styled.div`
  position: relative;
  display: flex;
  gap: 40px;
  width: auto;
  height: auto;
`

const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  padding: 5px;
  background-color: ${(props) => (props.bg ? props.bg : "#7A7A7A")};
  color: ${(props) => (props.color ? props.color : "#FFF")};
  border: none;
  outline: none;
  cursor: pointer;
  margin: 10px 0;
  transition: all 300ms ease-in-out;
  &:hover {
    transform: scale(1.1);
  }
`;

const InputData = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  @media (min-width: 1024px) {
    width: ${(props) => (props.width ? props.width : "inherit")};
  }
`;

const StyledTextfield = styled(TextField)`
  width: 100%;
  @media (min-width: 1024px) {
    width: ${(props) => (props.width ? props.width : "inherit")};
  }
`;


const InputError = styled.span`
  width: fit-content;
  height: ${(props) => (props.show ? "auto" : 0)};
  overflow: hidden;
  color: ${(props) => props.theme.palette.error};
  padding: ${(props) => (props.show ? "8px 10px" : 0)};
  opacity: ${(props) => (props.show ? 1 : 0)};
  border: ${(props) => props.theme.palette.error};
  background: ${(props) => `${props.theme.palette.error}10`};
  border-radius: 8px;
  margin-bottom: 10px;
  font-size: 0.875rem;
  transition: all 300ms ease-in-out;
`;

const CardsBandContentMedia = ({id, editData, handleDelete, handleMediaData, handleClose }) => {
  const theme = React.useContext(ThemeContext);

  const intl = useIntl();

  const [errors, setErrors] = React.useState({
    title: {
      value: false,
      text: intl.formatMessage({ id: "title must not be empty" }),
      validate: (value) => value == null || value === "", // returns true if there is an error and false if there is not
    },
    text: {
      value: false,
      text: intl.formatMessage({ id: "text must not be empty" }),
      validate: (value) => value == null || value === "", // returns true if there is an error and false if there is not
    }
  });

  const onChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setData({
      ...data,
      [name]: value,
    });
  };

  const [data, setData] = React.useState(
    editData
      ? {
          ...editData
        }
      : {
          title: "",
          text: "",
          link: "",
        }
  );

  const validateForm = () => {
    let foundError = false;
    const newErrors = {};
    Object.keys(errors).forEach((err) => {
      if (!foundError) {
        foundError = errors[err].validate(data[err]);
      }
      const val = errors[err].validate(data[err]);
      newErrors[err] = {
        ...errors[err],
        value: val,
      };
      setErrors({
        ...errors,
        ...newErrors,
      });
    });
    return !foundError;
  };

  const submit = () => {
    if (validateForm()) {
      handleMediaData(id, {
        ...data,
        positionHData: data?.positionHData?.value,
        positionVData: data?.positionVData?.value
      });
      handleClose();
    }
  };

  const handleCancel = (e) => {
    editData ? handleClose() : handleDelete(e);
  }

  return (
    <Root>
      <Title>
        {editData ? translate("edit content") : translate("add content")}
      </Title>
      <InputData width="100%">
        <StyledTextfield
          id="title"
          name="title"
          onChange={onChange}
          value={data?.title}
          label={intl.formatMessage({ id: "title" })}
          variant="outlined"
          width="100%"
          margin="normal"
          size="small"
          required
        />
        <InputError show={errors.title.value}>{errors.title.text}</InputError>
      </InputData>
      <InputData width="100%">
        <StyledTextfield
          id="text"
          name="text"
          onChange={onChange}
          value={data?.text}
          label={intl.formatMessage({ id: "text" })}
          variant="outlined"
          width="100%"
          margin="normal"
          size="small"
          required
        />
        <InputError show={errors.text.value}>{errors.text.text}</InputError>
      </InputData>
      <StyledTextfield
        id="link"
        name="link"
        onChange={onChange}
        value={data?.link}
        label={intl.formatMessage({ id: "link" })}
        variant="outlined"
        width="100%"
        margin="normal"
        size="small"
      />
      <ButtonRow>
        <Button
        onClick={handleCancel}
        bg={theme.palette.details}
        color={theme.palette.base}
        >
          {theme.icons.close}
        </Button>
          <Button
          onClick={submit}
          bg={theme.palette.accent}
          color={theme.palette.base}
        >
            {theme.icons.check}
        </Button>
      </ButtonRow>
    </Root>
  );
};

export default CardsBandContentMedia;
