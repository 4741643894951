import { alertConstants } from "./alertConstants";

const initialState = { 
  open:false,
};

export function alertMessage(state = initialState, action) {
  switch (action.type) {
    case alertConstants.SUCCESS:
      return {
        type: alertConstants.SUCCESS,
        message: action.message,
        open:true,
      };
    case alertConstants.INFO:
      return {
        type: alertConstants.INFO,
        message: action.message,
        open:true,
      };
    case alertConstants.WARNING:
      return {
        type: alertConstants.WARNING,
        message: action.message,
        open:true,
      };
    case alertConstants.ERROR:
      return {
        type: alertConstants.ERROR,
        message: action.message,
        open:true,
      };
    case alertConstants.CLEAR:
      return {
        open:false,
      };
    default:
      return state
  }
}
